// KEEP THIS FILE FREE FROM REACT COMPONENTS OR IT WILL BREAK CYPRESS UNIT TESTS.

import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import isAfter from 'date-fns/isAfter';
import { BeaconSelectOptionType } from 'views/BeaconManagementPage/types';
import { differenceInMinutes, differenceInSeconds, format, parseISO } from 'date-fns';
import { ReactSelectValueType } from 'views/EditAssetPage/types';
import { EstimateType } from 'types/Estimate';
import { VenueListType } from 'types/venue';
import { featureSetType } from 'types/config';
import { CONFIG_API_ENDPOINT, DATA_API_ENDPOINT } from 'config/constants';
import { UserType } from 'types/userInfo';
import { EddystoneProfile, iBeaconProfile } from 'types/BroadcastProfile';

export const countSearchResults = (results: any) => {
  let pageObjects: number = 0;
  results?.pages.forEach((res: any) => {
    pageObjects = pageObjects + res.count;
  });
  return pageObjects;
};

export const combineSearchResultsPages = (searchResults: any) => {
  const tempRes: EstimateType[] = [];
  searchResults?.pages.forEach((res: any) => {
    res.results.forEach((row: EstimateType) => {
      tempRes.push(row);
    });
  });
  return tempRes;
};

export const replaceUnderscoresWithSpaces = (text: string | undefined) => {
  if (text !== undefined) {
    return text.replaceAll('_', ' ');
  }
  return '';
};

export const getAccessToken = (authContext: any) => {
  return authContext.signInUserSession.accessToken.jwtToken;
};

export const getIdToken = (authContext: any) => {
  if (!authContext.signInUserSession) return null;
  return authContext.signInUserSession.idToken.jwtToken;
};

export const getUUID = () => {
  return uuidv4();
};

export const generateFlyoutTitle = (assetDetails: EstimateType) => {
  const titleString = `${assetDetails.asset_name || 'Unknown'}
 - 
  ${assetDetails.asset_type}`;
  return titleString;
};

export const tokenHasExpired = (jwtToken: string): boolean => {
  // a function to check if the time now is later than JWT tokens exp time and return boolean.
  if (!jwtToken) return true;
  const decoded: any = jwt_decode(jwtToken);
  const tokenExp = decoded.exp * 1000; // add extra 0s to give correct time value.
  const timeNow = Date.now();
  const tokenHasExpired: any = isAfter(timeNow, tokenExp);

  return tokenHasExpired;
};

export const getJWTTokenExpiryTime = (jwtToken: string): number => {
  const accessTokenDecoded: any = jwt_decode(jwtToken);
  const accessTokenExpTime = accessTokenDecoded.exp * 1000;

  return accessTokenExpTime;
};

export const checkRowsHaveAssignedAsset = (rows: EstimateType[]) => {
  const assignedAsset = rows.find((row) => row.asset_id && row.asset_id !== null);

  return assignedAsset !== undefined;
};

export const objIsEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export function getDefaultDropdownObj(options: BeaconSelectOptionType[]) {
  return options.filter((option) => option.default)[0];
}

export const replaceWordsInDistanceString = (distance: string): string => {
  return distance
    .replace(/hour/i, 'hr')
    .replace(/minute/i, 'min')
    .replace(/second/i, 'sec');
};

export function getLastSeenString(timestamp: string) {
  if (!timestamp) return '––';
  const lastSeenDateObj: any = new Date(timestamp);
  let lastSeenDate = '';

  if (lastSeenDateObj.toString() !== 'Invalid Date') {
    lastSeenDate = `(${format(lastSeenDateObj, 'dd/LL/yyyy - HH:mm')})`;
  }

  return lastSeenDate;
}

export function getAssetsNonExpired(assetData: EstimateType[]): EstimateType[] {
  // function that filters out assets that have expired.
  // loops through assets, and only returns assets that do not have expires
  // if they do have expires value, we only allow assets that have not yet expired (isBefore)

  const filteredData: EstimateType[] = [];
  // const timeNow = Date.now();

  assetData.forEach((item: EstimateType) => {
    // TODO: check expired value
    filteredData.push(item);
  });

  return filteredData;
}

export const getValueSafely = (
  target: any,
  endOfDataString?: string,
  showEmptyString?: boolean,
) => {
  if (!target && showEmptyString) {
    return '';
  }
  if (!target) {
    return '––';
  }
  return `${target}${endOfDataString ? endOfDataString : ''}`;
};

export function getNextCount(paginationIndex: number, totalCount?: number) {
  if (!totalCount) return 0;
  const countOfShownRows = (paginationIndex + 1) * 50; // add 1 so we can multiply from 1.
  const nextCount = totalCount - countOfShownRows;
  // if total is more or equal to amount shown plus 50, next page will be at least 50.

  if (totalCount > countOfShownRows + 50) return 50;
  // otherwise return remaining amount if over 0.
  else return nextCount > 0 ? nextCount : 0;
}

export function humaniseStringCasing(string: string) {
  let i = 0;
  let frags = string.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export function getSortedArrayByValue(arr: any[], value: string): any {
  return arr.sort((a: any, b: any) => a[value].toLowerCase().localeCompare(b[value].toLowerCase()));
}

function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

export function clearLocalStorageAndCookies() {
  localStorage.clear();
  deleteAllCookies();
  console.log('[Localstorage & cookies cleared]');
}

export function checkForMultipleErrorTypes(errors: any) {
  let hasError = false;
  if (!errors.types) return hasError;

  Object.values(errors.types).forEach((error) => {
    if (typeof error === 'string') {
      hasError = true;
    }
  });
  return hasError;
}

export function checkInputHasErrors(errors: any, fieldID: string) {
  let hasErrors = false;
  if (!errors[fieldID]) return false;

  if (errors.types) {
    hasErrors = checkForMultipleErrorTypes(errors);
  }

  hasErrors = Object.keys(errors[fieldID]).length > 0;

  return hasErrors;
}

export function checkInputIsDirty(dirtyFields: any, fieldID: string) {
  if (!dirtyFields[fieldID]) return false;
  return Object.keys(dirtyFields[fieldID]).length > 0;
}

export function windowBeforeUnloadEventHandler(event: any) {
  event.preventDefault();
  return (event.returnValue = 'Do you want to leave this site? Changes you made may not be saved.');
}

export function getCognitoDetailsFromConfigBootstrap(data: any) {
  // function to return object containing cognito details from strings provided from bootstrap endpoint.
  const issuerStr = data.issuer;
  const userPoolStrIndex = issuerStr.lastIndexOf('/');
  const cognitoUserPoolID = issuerStr.slice(userPoolStrIndex + 1);
  const cognitoDomain = new URL(data.logout_uri).host;
  const cognitoRegion = cognitoUserPoolID.substring(0, cognitoUserPoolID.indexOf('_'));
  return { cognitoUserPoolID, cognitoDomain, cognitoRegion };
}

export function getAddAssetSuccessMessage(derivedMutation: any) {
  return `${derivedMutation.variables.asset.asset_name} - ${replaceUnderscoresWithSpaces(
    derivedMutation.variables.asset.asset_type_id,
  )} has been added`;
}

export function getAssetDetailsFromLocalStorage(): EstimateType {
  const assetDetails = localStorage.getItem('assetDetails');

  return assetDetails ? JSON.parse(assetDetails) : null;
}

export function getReactSelectObjectFromValueKey(
  items?: ReactSelectValueType[],
  defaultValue?: string,
) {
  // a function that finds a single matching value from an array of data, and returns this as an array with 1 object.
  if (!items) return;
  return items.filter((item) => {
    return item.value.toLowerCase() === defaultValue?.toLowerCase();
  });
}

export function getWindowURL() {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;
}

function isProdEnv() {
  return process.env.NODE_ENV === 'production';
}

type BaseApiUrl = {
  type: 'base_api_url';
  url: string;
};

type CompositeApiUrl = {
  type: 'composite_api_url';
  config_api_url: string;
  data_api_url: string;
};

type ApiUrl = BaseApiUrl | CompositeApiUrl;

function parseApiUrl(): ApiUrl {
  const serializedData = process.env.REACT_APP_LOCAL_DEV_BASE_URL as string;
  try {
    const data = JSON.parse(serializedData);
    return {
      type: 'composite_api_url',
      config_api_url: data['config_api_url'],
      data_api_url: data['data_api_url'],
    };
  } catch (e) {
    if (e instanceof SyntaxError) {
      return {
        url: serializedData,
        type: 'base_api_url',
      };
    }
    throw e;
  }
}

export function getConfigApiUrl() {
  if (isProdEnv()) {
    return `${getWindowURL()}${CONFIG_API_ENDPOINT}`;
  }

  const apiUrl = parseApiUrl();
  if (apiUrl.type === 'base_api_url') {
    return `${apiUrl.url}${CONFIG_API_ENDPOINT}`;
  } else {
    return apiUrl.config_api_url;
  }
}

export function getDataApiUrl() {
  if (isProdEnv()) {
    return `${getWindowURL()}${DATA_API_ENDPOINT}`;
  }

  const apiUrl = parseApiUrl();
  if (apiUrl.type === 'base_api_url') {
    return `${apiUrl.url}${DATA_API_ENDPOINT}`;
  } else {
    return apiUrl.data_api_url;
  }
}

export function shouldUseCognito() {
  return process.env.REACT_APP_USE_COGNITO !== 'false';
}

function mappedOriginUrls() {
  const urlMappings = process.env.REACT_APP_ORIGIN_MAPPING;
  if (urlMappings == null) {
    return isProdEnv()
      ? {}
      : {
          'http://venue:8000': 'http://localhost:8002',
          'http://localstack:4566': 'http://localhost:4566',
        };
  }
  return JSON.parse(urlMappings);
}

export function getMappedUrl(urlString: string) {
  const urls = mappedOriginUrls();

  const url = new URL(urlString);

  if (!(url.origin in urls)) {
    return urlString;
  }

  const newOrigin = urls[url.origin];
  const urlPath = urlString.slice(url.origin.length, urlString.length).replace(/^\/+/, '');

  if (urlPath.length === 0 || urlPath === '/') {
    return newOrigin;
  }
  return `${newOrigin}/${urlPath}`;
}

export function programaticallyDownloadFile(url: Blob, fileName: string) {
  // create file link in browser's memory, and trigger download programatically.
  const href = URL.createObjectURL(url);
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  // remove file from DOM.
  document.body.removeChild(link);
}

export function programaticallyDownloadFileFromHref(href: string) {
  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
  // remove file from DOM.
  document.body.removeChild(link);
}

export function getArraysIntersection(a1: string[], a2: string[]) {
  return a1.filter((n: string) => {
    return a2.indexOf(n) !== -1;
  });
}

export function getPermissionsArrayFromObject(featureset: featureSetType) {
  let permissionsArray: string[] = [];

  for (const [key, value] of Object.entries(featureset)) {
    if (typeof value === 'boolean' && value === true) {
      permissionsArray.push(key);
    }
  }
  return permissionsArray;
}

export function scrollWindowToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

type urlParamArrayType = [key: string, value: string];

export function setWindowURLParams(...urlParams: urlParamArrayType[] | urlParamArrayType) {
  // function to accept dynamic amount of params, then set params into history and update URL.
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const paramsArray = [...urlParams];

  for (const [key, value] of paramsArray) {
    params.set(key, value);
  }

  window.history.replaceState({}, '', `${location.pathname}?${params}`);
}

export function clearWindowURLParams() {
  // function to remove all url params and update history so URL updates.
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  for (const [key] of params.entries()) {
    params.delete(key);
  }
  window.history.replaceState({}, '', `${location.pathname}`);
}

export function getPhysicalMapVenuesOnly(venues: VenueListType[]) {
  let physicalMapVenues: VenueListType[] = [];

  venues.forEach((venue) => {
    venue.latest_versions.forEach((version) => {
      if (version.dataset === 'physical_map') {
        physicalMapVenues.push(venue);
      }
    });
  });

  return physicalMapVenues;
}

export function setVHCustomHeightCSSProperty() {
  // for use in map container styling.
  let vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export function getBeaconIntergration(integrations: any): string {
  // get matching single integration that has a type of 'beacon_import', otherwise return null

  let matchingIntergration = '';

  Object.entries(integrations).forEach((integration: any) => {
    if (integration[1].type === 'beacon_import') {
      matchingIntergration = integration[0];
    }
  });

  return matchingIntergration;
}

export function checkStringMatchesRegex(string: string, regexPattern: RegExp) {
  return regexPattern.test(string);
}

export function getUsersActiveLastHour(users: UserType[]): UserType[] {
  const dateNow = new Date();

  return users.filter((user) => {
    const formattedEstimateDate = new Date(user.last_update_timestamp);
    const diffInMins = differenceInMinutes(dateNow, formattedEstimateDate);

    // returns true if last update time is before time last hour from now
    return diffInMins >= 0 && diffInMins < 60;
  });
}

export function assetEstimateIsNotFresh(
  estimateTimestamp: string,
  freshPeriodThresholdSeconds: number,
): boolean {
  // simple function that returns true if the assets estimate is greater than the fresh period defined from config.
  const timeNow = new Date();
  const timeDifferenceInSeconds = differenceInSeconds(timeNow, new Date(estimateTimestamp));
  return timeDifferenceInSeconds > freshPeriodThresholdSeconds;
}

export function userEstimateIsNotFresh(
  estimateTimestring: any,
  freshPeriodThresholdSeconds: number,
): boolean {
  // simple function that returns true if the users estimate is older than the fresh period defined from config.
  const timeNow = new Date();
  const estimateDate = parseISO(estimateTimestring);
  const timeDifferenceInSeconds = differenceInSeconds(timeNow, estimateDate);

  return timeDifferenceInSeconds > freshPeriodThresholdSeconds;
}

export function getCalculatedRSSIValue(broadcastProfile: EddystoneProfile | iBeaconProfile) {
  if (!broadcastProfile || !broadcastProfile?.tx_power) return null;

  // if tx_power has length of 2, and includes '-' it must be a tx_value such as '-24'
  if (
    broadcastProfile.tx_power.toString().includes('-') &&
    broadcastProfile.tx_power.toString().length >= 2
  ) {
    return `${broadcastProfile.tx_power}`;
    //otherwise, the tx_power will correspond to the index from the calibrated_rssi array
  } else if (broadcastProfile.calibrated_rssi) {
    return `${broadcastProfile.calibrated_rssi[broadcastProfile.tx_power]}`;
  } else return null;
}
