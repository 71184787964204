import styled from 'styled-components';
import SlidingPane from 'react-sliding-pane';
import { colours } from 'styling/colours';
import bigX from './icons/bigX.svg';
import { MOBILEDEVICEWIDTH } from 'config/constants';

export const StyledAssetListFlyout = styled(SlidingPane)`
  width: 617px !important;
  .information-sentance {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    margin-bottom: 20px;
    img {
      margin-right: 5px;
      width: 14px;
      height: 14px;
    }
    p {
      color: #808285;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
  & .slide-pane__header {
    background: ${colours.assetDetailsFlyoutHeader};
    position: relative;
    padding: 35px 0;
    height: 80px;
  }
  & .slide-pane__content {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }
  .hr {
    width: 100%;
    background: #62626233;
    height: 1px;
    margin: 40px auto;
    padding: 0.5px 0;
  }
  & .slide-pane__header * {
    overflow-x: ellipsis;
  }
  & .slide-pane__close {
    position: absolute;
    right: 20px;
    top: 25px;
    background: url(${bigX}) 0 0 no-repeat;
    background-size: 16px 16px;
    background-position-x: center;
    background-position-y: center;
    opacity: 100%;
  }
  & .slide-pane__close svg {
    display: none;
  }
  & h2.slide-pane__title {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0px;
    color: ${colours.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .slide-pane__subtitle {
    color: ${colours.white};
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    width: 100% !important;

    h2.slide-pane__title {
      font-size: 18px;
    }
    .slide-pane__close {
      right: 15px;
      top: 15px;
    }
    .hr {
      margin: 20px auto;
    }
    .region {
      margin-left: 50px;
    }
  }
`;

export const StyledButtonsContainer = styled.div`
  height: 60px;
  text-align: right;
  margin-top: auto;
  padding-right: 30px;
  button {
    margin-top: 10px;
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const StyledTable = styled.table`
  padding: 0 30px;
  display: block;
  tr {
    vertical-align: text-top;
    & td:first-child {
      padding-bottom: 0;
    }
  }
  td {
    font-size: 15px;
    letter-spacing: 0.09545454382896423px;
    padding-bottom: 15px;
    display: inline-block;
    word-break: break-word;

    &:first-child {
      width: 140px;
      color: #808285;
    }
    &:nth-of-type(2) {
      width: 380px;
    }
  }

  td#assetDetailsDescription {
    overflow: hidden;
    word-break: break-word;
    white-space: pre-line;
  }
  &:last-of-type {
    margin-bottom: 30px;
  }

  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    td {
      width: 100% !important;
    }
  }
`;
