import React, { useContext, useState } from 'react';
import format from 'date-fns/format';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { alertErrorMessage } from 'utils/alerts';
import Button from 'components/Button/Button';
import { BeaconManagementContext } from '../contexts/BeaconManagment.context';

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
  span {
    margin-right: 10px;
    color: ${colours.secondaryText};
  }
`;

export default function RefreshTimestampAndButton({ queries }: { queries: any[] }) {
  const [time, setTime] = useState(getTimeString());
  const { isRefreshing, setIsRefreshing, setSearchInProgress } =
    useContext(BeaconManagementContext);

  function getTimeString() {
    const timeNow = new Date();
    const year = format(timeNow, 'dd/MM/yyyy');
    const time = format(timeNow, 'HH.mm');
    return `${time} on ${year}`;
  }

  async function handleRefresh() {
    let index = 0;
    setSearchInProgress(true);
    setIsRefreshing(true);

    for await (const query of queries) {
      query
        .refetch()
        .then(() => {
          index++;
          if (index === queries.length) {
            setSearchInProgress(false);
            setTime(getTimeString());
            setTimeout(() => {
              // delay this value to prevent tab changing in TabController useEffect.
              setIsRefreshing(false);
            }, 500);
          }
        })
        .catch((err: any) => {
          console.error(err.response);
          alertErrorMessage('Could not refresh');
          setIsRefreshing(false);
          setSearchInProgress(false);
        });
    }
  }

  return (
    <StyledContainer>
      <span>Last updated at {time}</span>
      <Button outline isLoading={isRefreshing} onClick={handleRefresh}>
        Refresh
      </Button>
    </StyledContainer>
  );
}
