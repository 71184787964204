import React, { useEffect, useContext, useState } from 'react';
import { Progress } from 'antd';
import BeaconsApi from 'api/beacons/beacons.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { alertSuccessMessage, alertErrorMessage } from 'utils/alerts';
import AntModalStyled from 'components/Modals/AntModalStyled';
import { UnregisteredBeaconType } from 'types/BeaconType';

function getPercentage(beaconCount: number, index: number) {
  return Math.round((index / beaconCount) * 100);
}
function getSuccessString(beaconCount: number) {
  return beaconCount > 1
    ? `${beaconCount} New Beacons have been imported`
    : '1 New Beacon has been imported';
}

export default function ImportActionModal({
  selectedRows,
  setIsImporting,
  repopulateTables,
  setSearchTerm,
  setIsSearching,
}: {
  selectedRows: UnregisteredBeaconType[];
  setIsImporting: (val: boolean) => void;
  repopulateTables: () => void;
  setSearchTerm: (val: string) => void;
  setIsSearching: (val: boolean) => void;
}) {
  const [percentage, setPercentage] = useState(0);
  const beaconCount = selectedRows.length;
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const beaconsApi = new BeaconsApi(token);

  async function handleImportAssets(beacons: UnregisteredBeaconType[]) {
    // asyncronously call POST /beacons for each asset selected.
    // update index for each iteration, and update percentage.
    // close modals, and call repopulateTables with new latest data from API.
    let index = 0;

    for await (const beacon of beacons) {
      const date = new Date();

      beaconsApi
        .createBeacon({
          beacon: {
            ...beacon,
            import_info: {
              import_timestamp: date.toISOString(), // generate timestamp clientside.
              source: beacon.source,
              order_id: beacon.order_id,
            },
          },
        })
        .then(() => {
          index++;
          const updatedPercentage = getPercentage(beaconCount, index);
          setPercentage(updatedPercentage);
          if (index === beacons.length) {
            setTimeout(() => {
              setIsImporting(false);
              repopulateTables();
              alertSuccessMessage(getSuccessString(beacons.length));
              setSearchTerm('');
              setIsSearching(false);
            }, 500); // settimeout so we can see progress update.
          }
        })
        .catch((err) => {
          console.error(err.response);
          alertErrorMessage('Error importing beacon');
          setIsImporting(false);
        });
    }
  }
  useEffect(() => {
    handleImportAssets(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AntModalStyled visible centered footer={null} closable={false} maskClosable={false}>
      <header>
        <h3>Importing Beacons</h3>
      </header>
      <div className="progress-info">
        <span>{beaconCount} Beacons</span>
        <span>{percentage} %</span>
      </div>
      <Progress percent={percentage} showInfo={false} />
    </AntModalStyled>
  );
}
