import { UserType } from 'types/userInfo';
import {
  getAssociatedLevelIDsArrayFromOrdinal,
  getFloorAssetCountFromLevelIDArray,
  getFloorUserCountFromLevelIDArray,
  getLowestOrdinalThatHasAsset,
  getLowestOrdinalThatHasUser,
  getLevelFeaturesSortedByOrdinal,
} from './utils';
import { EstimateType } from 'types/Estimate';

// a large function to determine what ordinal should be selected based on a complex set of conditions
// we return the respective ordinal based on these conditions.
export function getOrdinalFromConditions(
  assetMarkers: EstimateType[],
  usersMarkers: UserType[],
  associatedBuildingLevelFeatures: any,
  currentFloorOrdinal: number | null,
  inFilterMode: boolean,
  inSearchMode: boolean,
): number {
  const sortedLevels = getLevelFeaturesSortedByOrdinal(associatedBuildingLevelFeatures);
  const lowestOrdinal = sortedLevels[0].properties.ordinal; // find lowest available ordinal
  const currentOrdinal = currentFloorOrdinal !== null ? currentFloorOrdinal : lowestOrdinal; // use currently selected ordinal if not null, otherwise default to lowest floor.
  const assetsReturned = assetMarkers.length > 0;
  const usersReturned = usersMarkers.length > 0;
  const currentLevelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
    currentOrdinal,
    associatedBuildingLevelFeatures,
  );
  const lowestOrdinalWithAsset = getLowestOrdinalThatHasAsset(
    sortedLevels,
    assetMarkers,
    currentOrdinal,
  );
  const lowestOrdinalWithUser = getLowestOrdinalThatHasUser(
    sortedLevels,
    usersMarkers,
    currentOrdinal,
  );
  const currentFloorAssetCount = getFloorAssetCountFromLevelIDArray(
    currentLevelIDsArray,
    assetMarkers,
  );
  const currentFloorUserCount = getFloorUserCountFromLevelIDArray(
    currentLevelIDsArray,
    usersMarkers,
  );

  const currentFloorHasAssets = currentFloorAssetCount > 0;
  const currentFloorHasUsers = currentFloorUserCount > 0;

  // ===============================
  // floor changing condition logic
  // ===============================
  let ordinalToReturn = lowestOrdinal; // keep select lowest floor.

  // filter search conditions first ====================

  if (inFilterMode || inSearchMode) {
    if (!assetsReturned && !usersReturned) {
      ordinalToReturn = currentOrdinal;
      return ordinalToReturn;
    } else if (!assetsReturned) {
      ordinalToReturn = currentOrdinal;
      return ordinalToReturn;
    } else if (currentFloorHasAssets) {
      // if user is searching or filtering, we return floor with searched asset on.
      ordinalToReturn = currentOrdinal;
      return ordinalToReturn;
    } else if (assetsReturned) {
      ordinalToReturn = lowestOrdinalWithAsset;
      return ordinalToReturn;
    } else {
      ordinalToReturn = lowestOrdinalWithAsset;
    }
  }

  // If there are no people or assets on the current floor then it should switch to lowest floor with assets, or remain on the current floor if there are no assets in the venue.
  if (!assetsReturned && !usersReturned) {
    // if no assets or users, just return current floor.
    ordinalToReturn = currentOrdinal;
    return ordinalToReturn;
  }
  if (assetsReturned) {
    // return lowest floor with asset if there is one
    ordinalToReturn = lowestOrdinalWithAsset;
    return ordinalToReturn;
  }
  if (usersReturned && !assetsReturned) {
    // if users but no assets, return lowest floor with user
    ordinalToReturn = lowestOrdinalWithUser;
  }
  if (currentFloorHasAssets) {
    // If there are assets on the current floor then keep the floor selected.
    ordinalToReturn = currentOrdinal;
  }
  // If there are people on the current floor, but no assets, then floor selection should not change
  if (!currentFloorHasAssets && currentFloorHasUsers) {
    ordinalToReturn = currentOrdinal;
  }

  return ordinalToReturn;
}
