import React from 'react';
import PageTitle from 'components/Header/components/PageTitle';
import UserInfoDropdown from './components/UserInfoDropdown/UserInfoDropdown';
import usePageInfo from 'hooks/pageInfo.hook';
import { HeaderStyled, StyledSubHeading } from './style';
import useDevice from 'hooks/useDevice';

type HeaderDefaultProps = {
  itemCount?: number;
  CountTitle?: string;
};

const HeaderDefault = ({ itemCount, CountTitle }: HeaderDefaultProps) => {
  const { contextPageTitle } = usePageInfo();
  const hasItemCount = itemCount !== undefined;
  const { isTabletDevice } = useDevice();
  return (
    <div className="container">
      <HeaderStyled className="page-header">
        <div className="header-item title">
          <PageTitle pageTitle={contextPageTitle} />

          {hasItemCount && (
            <StyledSubHeading data-test-id="userCount">
              {`#${itemCount.toString().padStart(2, '0')} ${CountTitle}`}
              {itemCount === 1 ? '' : 's'}
            </StyledSubHeading>
          )}
        </div>
        {!isTabletDevice && (
          <div className="header-item">
            <UserInfoDropdown />
          </div>
        )}
      </HeaderStyled>
    </div>
  );
};

export default HeaderDefault;
