import AssetsApi from 'api/assets/assets.api';
import { useContext } from 'react';
import { getIdToken } from 'utils/utils';
import { AssetsContext } from '../contexts/assets.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { VenuesContext } from '../contexts/venues.context';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import {
  checkAssetIsInSameBuilding,
  checkAssetIsOnSelectedFloor,
  getAssetsWithBuildingAndEstimate,
  getIntersectionFilteredAssets,
  getOutdoorEstimates,
  checkAssetEstimateLocationIsDifferentToRef,
} from '../utils/utils';
import { MapControlContext } from '../contexts/mapcontrol.context';
import useMapHooks from './useMapHooks';

import useMapHooksExternalMapRef from './useMapHooksExternalMapRef';
import { EstimateType } from 'types/Estimate';

export default function useCallAssetsAPIIntervalFunction() {
  const { setRawIndoorAssets, setRawOutsideMappedBuildingsAssets, cachedAssetRef } =
    useContext(AssetsContext);
  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;

  const {
    inSearchMode,
    inFilterMode,
    assetSubtypeCheckedListArray,
    assetOwnerCheckedListArray,
    searchTerm,
  } = useContext(SearchFilterContext);
  const {
    setPollAssetsFinished,
    setShouldRecalculateFloor,
    floorSelectedIDArray,
    buildingSelectedID,
    setBuildingSelectedID,
  } = useContext(MapControlContext);
  const { levelsFeatures } = useContext(VenueFilesContext);
  const { checkAssetLatLngIsWithinMapBounds } = useMapHooks();

  const { fitMapToBoundsOfBuildingLevelsWithAssetsAndOutdoors } = useMapHooksExternalMapRef();

  function handleSingleAssetReturnedFromSearch(singleAsset: EstimateType) {
    // logic for single asset returned usecases.
    const { latitude, longitude } = singleAsset.location.coordinates;
    const estimateCoordsArray = [latitude, longitude];
    const assetLocationChanged = checkAssetEstimateLocationIsDifferentToRef(
      singleAsset,
      cachedAssetRef,
    );
    const assetIsOutdoors = singleAsset.location.is_outdoors;
    const assetIsWithinMapBounds = checkAssetLatLngIsWithinMapBounds(estimateCoordsArray);
    const assetIsInSelectedBuilding = assetIsOutdoors
      ? false
      : checkAssetIsInSameBuilding(singleAsset, buildingSelectedID);
    const assetIsOnSelectedFloor = assetIsOutdoors
      ? false
      : checkAssetIsOnSelectedFloor(singleAsset, floorSelectedIDArray);
    const assetIsVisibleToUser = assetIsOutdoors
      ? assetIsWithinMapBounds
      : assetIsWithinMapBounds && assetIsOnSelectedFloor && assetIsInSelectedBuilding;

    if (assetLocationChanged && !assetIsVisibleToUser) {
      const assetWasInSelectedBuildingLastPoll = checkAssetIsInSameBuilding(
        cachedAssetRef.current,
        buildingSelectedID,
      );
      const assetWasOutdoorsLastPoll = cachedAssetRef.current.location.is_outdoors;

      if (assetWasInSelectedBuildingLastPoll && !assetIsInSelectedBuilding && !assetIsOutdoors) {
        // if asset was in selected building and  has moved buildings, select new building, and pan map to show asset and prev building

        fitMapToBoundsOfBuildingLevelsWithAssetsAndOutdoors(
          levelsFeatures,
          [singleAsset],
          buildingSelectedID,
        );
        setBuildingSelectedID(singleAsset.location.building_level.possible_buildings[0].id); // this will automatically trigger floor calc.
      } else if (assetWasOutdoorsLastPoll && !assetIsOutdoors) {
        // check if asset was outdoors in last poll, and has now moved indoors. Select building it moved to floor, and pan.

        fitMapToBoundsOfBuildingLevelsWithAssetsAndOutdoors(
          levelsFeatures,
          [singleAsset],
          buildingSelectedID,
        );
        setBuildingSelectedID(singleAsset.location.building_level.possible_buildings[0].id); // this will automatically trigger floor calc.
        setShouldRecalculateFloor(true); // set recaculatefloorflag to true so that useFloorSelectionHook logic will run
      } else if (!assetWasOutdoorsLastPoll && assetIsOutdoors) {
        // if asset was indoors, and has now moved outdoors,
        // check asset is within bounds, if not, pan.
        if (!assetIsWithinMapBounds) {
          fitMapToBoundsOfBuildingLevelsWithAssetsAndOutdoors(
            levelsFeatures,
            [singleAsset],
            buildingSelectedID,
          );
        }
        // check if asset is within bounds of map, and adjust if not.
      } else if (!assetWasInSelectedBuildingLastPoll && !assetIsInSelectedBuilding) {
        // if asset has moved buildings, adjust map if necessary for building of asset, whilst keeping current building within view.
        fitMapToBoundsOfBuildingLevelsWithAssetsAndOutdoors(
          levelsFeatures,
          [singleAsset],
          buildingSelectedID,
        );
      } else if (assetIsInSelectedBuilding) {
        // if asset as only moved in selected building
        setShouldRecalculateFloor(true); // set recaculatefloorflag to true so that useFloorSelectionHook logic will run
      }
      cachedAssetRef.current = singleAsset; // set ref to the latest location so we can check against it on next poll.
    }
  }

  function callAssetsAPIIntervalFunction() {
    // This function is called on each poll.
    // using localstorage for token instead of context, as we need the latest token each time, and cant use usecontext inside the interval function.
    const localStorageState: any = localStorage.getItem('aws-amplify-cacheauthState');
    const authState = JSON.parse(localStorageState).data;
    const token = getIdToken(authState);
    const assetsApi = new AssetsApi(token);

    async function callDerivedAPIMethod() {
      // If there is a search or filter set by user, we need to use the respective API call for that for the polling.
      if (inSearchMode) {
        return assetsApi.getRegisteredBeaconMapAssets(selectedVenueID, searchTerm);
      } else if (inFilterMode) {
        return assetsApi.getRegisteredBeaconFilteredMapAssets(
          selectedVenueID,
          assetOwnerCheckedListArray,
          assetSubtypeCheckedListArray,
        );
      } else {
        // if no filter / searches, we call  api with only venuename param.
        return assetsApi.getRegisteredBeaconMapAssets(selectedVenueID);
      }
    }
    setPollAssetsFinished(false);
    setShouldRecalculateFloor(false);

    callDerivedAPIMethod().then((res) => {
      const { items } = res.data;
      const estimatesWithBuilding = getAssetsWithBuildingAndEstimate(items);
      const outdoorEstimates = getOutdoorEstimates(items);
      const combinedEstimates = [...estimatesWithBuilding, ...outdoorEstimates];
      if (inFilterMode) {
        // apply intersectional filtering if filtermode is active
        const intersectionalFilteredIndoorAssets = getIntersectionFilteredAssets(
          estimatesWithBuilding,
          assetOwnerCheckedListArray,
          assetSubtypeCheckedListArray,
        );
        const intersectionalFilteredOutdoorAssets = getIntersectionFilteredAssets(
          outdoorEstimates,
          assetOwnerCheckedListArray,
          assetSubtypeCheckedListArray,
        );
        setRawIndoorAssets(intersectionalFilteredIndoorAssets);
        setRawOutsideMappedBuildingsAssets(intersectionalFilteredOutdoorAssets);
      } else {
        setRawIndoorAssets(estimatesWithBuilding);
        setRawOutsideMappedBuildingsAssets(outdoorEstimates);
      }

      if (inSearchMode && combinedEstimates.length === 1) {
        handleSingleAssetReturnedFromSearch(combinedEstimates[0]);
      }
      setPollAssetsFinished(true);
    });
  }

  return { callAssetsAPIIntervalFunction };
}
