import { FullConfigContext } from 'contexts/fullConfig.context';
import usePageVisibility from 'hooks/usePageVisibility';
import { useContext, useEffect, useRef } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import useCallAssetsAPIIntervalFunction from '../hooks/useCallAssetsAPIIntervalFunction';
import useCallUsersAPIIntervalFunction from '../hooks/useCallUsersAPIIntervalFunction';
import useDevice from 'hooks/useDevice';

// a component that encapsulates an interval to call API, that is initialised on component mount.
function PollingIntervalComponent(): null {
  const { callAssetsAPIIntervalFunction } = useCallAssetsAPIIntervalFunction();
  const { callUsersAPIIntervalFunction } = useCallUsersAPIIntervalFunction();

  const pollInterval = 10000;
  const { floorSelectedIDArray } = useContext(MapControlContext);
  const { assetTrackingPermissionEnabled, userTrackingPermissionEnabled } =
    useContext(FullConfigContext);
  const { windowIsVisible } = usePageVisibility();
  const intervalHasUnmounted = useRef(false);
  const { isMobileDevice } = useDevice();

  useEffect(() => {
    // call GET assets & users at every interval, and update markers with data returned from API.
    // only to run if permissions are enabled
    if (windowIsVisible) {
      const initialisePollingIntervalComponent: any = setInterval(() => {
        if (assetTrackingPermissionEnabled) {
          callAssetsAPIIntervalFunction();
        }
        if (userTrackingPermissionEnabled && !isMobileDevice) {
          // disable users on mobile.
          callUsersAPIIntervalFunction();
        }
      }, pollInterval);
      //  clear interval and re-initialise when the floor changes, so we dont retain context values in closure.
      // also if page visibility changes, so we dont needlessly poll api.
      return () => {
        intervalHasUnmounted.current = true;
        clearInterval(initialisePollingIntervalComponent);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorSelectedIDArray, windowIsVisible]);

  useEffect(() => {
    // useEffect to run poll func immediately after user returns to screen
    // only to run if interval has already unmounted once, so we dont call api on component mount.
    if (windowIsVisible && intervalHasUnmounted.current) {
      if (assetTrackingPermissionEnabled) {
        callAssetsAPIIntervalFunction();
      }
      if (userTrackingPermissionEnabled && !isMobileDevice) {
        callUsersAPIIntervalFunction();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowIsVisible, isMobileDevice]);

  return null;
}

export default PollingIntervalComponent;
