import ExportAssetsApi from 'api/assets/export.api';
import Button from 'components/Button/Button';
import { AuthenticationContext } from 'contexts/authentication.context';
import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import { getIdToken, programaticallyDownloadFileFromHref } from 'utils/utils';
import DownloadAssetsModal from './DownloadAssetsModal';
import { AssetListPageContext } from 'contexts/assetListPage.context';
import { add, isAfter } from 'date-fns';

const StyledButtonContainer = styled.div`
  margin-right: 10px;
`;
export default function DownloadAssetsButton() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [downloadModalOpenedTimestamp, setDownloadModalOpenedTimestamp] = useState<any>(null);
  const [jobID, setJobID] = useState('');

  const tickCountRef: any = useRef(null);

  const { authState } = useContext(AuthenticationContext);
  const { downloadAssetsButtonDisabled } = useContext(AssetListPageContext);

  const idToken = getIdToken(authState);
  const exportAssetsApi = new ExportAssetsApi(idToken);

  function handleFileStatus(res: any, interval: any) {
    // processing (also has progress value 0 -1 ) - the job is in progress.
    // export_succeeded (terminal) - the job succeded.
    // failed (terminal) - the job failed.
    const progressData = res.data;

    switch (progressData.status) {
      case 'processing':
        break;

      case 'export_succeeded':
        // note we close modal in useEffect after 3 seconds.
        clearInterval(interval);
        programaticallyDownloadFileFromHref(res.data.url);
        setIsFetching(false);
        setDownloadComplete(true);
        alertSuccessMessage('File has been downloaded successfully');

        break;

      case 'failed':
        setIsFetching(false);
        setModalOpen(false);
        alertErrorMessage('Error downloading file');

        break;
      default:
        break;
    }
  }

  function handleError(err: any) {
    console.log(err);
    alertErrorMessage('Error downloading file');
    setIsFetching(false);
  }

  function handleExport() {
    setIsFetching(true);
    // get job ID
    exportAssetsApi
      .createNewJob()
      .then((res) => {
        setJobID(res.data.job_id);

        return res.data.job_id;
      })
      // pass JobID to export endpoint
      .then((job_id) => {
        exportAssetsApi
          .getJobStatus(job_id)
          .then((res) => {
            handleFileStatus(res, tickInterval);
          })
          .catch((err) => {
            clearInterval(tickInterval);
            handleError(err);
          });
      })
      .catch((err) => {
        clearInterval(tickInterval);
        handleError(err);
      });

    // interval to count ticks lapsed since download was initiated.
    // we display the modal after 1 second lapses,
    // then cancel the interval failure.
    const tickInterval = setInterval(() => {
      tickCountRef.current = tickCountRef.current + 1;

      if (tickCountRef.current > 0 && !modalOpen) {
        setModalOpen(true);
        clearInterval(tickInterval);
        setDownloadModalOpenedTimestamp(Date.now());
      }
    }, 1000);
  }

  useEffect(() => {
    // useeffect to set polling of api after job id has been made.
    // also closes the modal, after request finishes or error.
    if (jobID !== '') {
      const interval = setInterval(() => {
        exportAssetsApi
          .getJobStatus(jobID)
          .then((res) => {
            handleFileStatus(res, interval);
          })
          .catch((e: any) => {
            // if theres an error, cancel interval.
            console.log(e);
            clearInterval(interval);
            setModalOpen(false);
            handleError(e);
          });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobID]);

  useEffect(() => {
    // useeffect that starts timer if download is complete & modalisOpen.
    // this checks the time that has passed since the modal was opened.
    // if time passed is over 3 seconds, we can close modal.
    // this is to prevent flashing of modal if it downloads fast.
    if (downloadComplete && modalOpen) {
      const timePassedThreshold = add(downloadModalOpenedTimestamp, { seconds: 3 });

      const interval = setInterval(() => {
        const timeNow = Date.now();
        const closeModalTimeHasPassed = isAfter(timeNow, timePassedThreshold);

        if (closeModalTimeHasPassed) {
          setModalOpen(false);
          clearInterval(interval);
        }
      }, 300);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadComplete, modalOpen]);

  return (
    <StyledButtonContainer>
      <Button
        style={{ width: '170px' }}
        outline
        isLoading={isFetching}
        onClick={handleExport}
        disabled={downloadAssetsButtonDisabled}
      >
        Download Assets
      </Button>
      {modalOpen && <DownloadAssetsModal />}
    </StyledButtonContainer>
  );
}
