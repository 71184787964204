import {
  getCombinedLevelAssetUserBounds,
  getCoordsFromAssets,
  getLevelFeatureDataFromBuildingID,
  getOutdoorEstimates,
} from '../utils/utils';
import L from 'leaflet';
import { latLngBounds } from 'leaflet';
import { useContext } from 'react';
import { MapContainerRefContext } from '../contexts/mapContainerRef.context';
import { UserType } from 'types/userInfo';
import { EstimateType } from 'types/Estimate';

export default function useMapHooksExternalMapRef() {
  // useEffect that uses leaflets map methods on mapstate passed in from mapContainerRef context.
  // functions are called by components and hooks that exist outside of mapcontainer eg search.
  const { mapContainerRef } = useContext(MapContainerRefContext);

  function fitMapToBoundsOfBuildingLevelsWithAssetsAndOutdoors(
    levelsFeaturesData: any,
    assets: EstimateType[],
    currentSelectedBuildingID?: string,
  ): void {
    // function that pans map to bounds of all building levels which contain assets.
    // and outdoor assets.
    // used for panning map after searching assets within multiple buildings.
    if (!mapContainerRef) return;

    let buildingIDArray: string[] = [];
    const bounds = latLngBounds([]); // start with empty bounds
    const outdoorAssets = getOutdoorEstimates(assets);
    const outdoorAssetCoords: any = getCoordsFromAssets(outdoorAssets);

    // first extend bounds to all outdoor asset coords.
    bounds.extend(outdoorAssetCoords);

    // get all unique building IDs from non outdoor Assets
    assets.forEach((asset) => {
      if (!asset.location.is_outdoors) {
        asset.location.building_level.possible_buildings.forEach((building) => {
          if (!buildingIDArray.includes(building.id)) {
            buildingIDArray.push(building.id);
          }
        });
      }
    });

    // add current selected building if it is passed in
    if (currentSelectedBuildingID) {
      if (!buildingIDArray.includes(currentSelectedBuildingID)) {
        buildingIDArray.push(currentSelectedBuildingID);
      }
    }

    // loop through all buildingIDs and get levels features data that match assets building ID
    buildingIDArray.forEach((buildingID) => {
      const buildingIDLevelsData = getLevelFeatureDataFromBuildingID(
        buildingID,
        levelsFeaturesData,
      );
      const geoJSONLayer = L.geoJSON(buildingIDLevelsData);
      // expand bounds to each buildings data.
      bounds.extend(geoJSONLayer.getBounds());
    });
    // finally pan to fully extended bounds.
    mapContainerRef.fitBounds(bounds);
  }

  function panMapToProvidedCombinedLevelsAssetUserBounds(
    combinedAssetBounds: EstimateType[],
    combinedUserBounds: UserType[],
    levelsFeatures: any,
  ) {
    if (!mapContainerRef) return;

    const bounds = getCombinedLevelAssetUserBounds(
      combinedAssetBounds,
      combinedUserBounds,
      levelsFeatures,
    );

    mapContainerRef.fitBounds(bounds, { animate: true });
  }

  function panMapToCombinedAssetBounds(combinedAssetBounds: EstimateType[]) {
    if (!mapContainerRef) return;

    const bounds = latLngBounds([]); // start with empty bounds
    const assetCoords: any = getCoordsFromAssets(combinedAssetBounds);

    bounds.extend(assetCoords);
    mapContainerRef.fitBounds(bounds, { animate: true, maxZoom: 20 });
  }

  return {
    panMapToCombinedAssetBounds,
    fitMapToBoundsOfBuildingLevelsWithAssetsAndOutdoors,
    panMapToProvidedCombinedLevelsAssetUserBounds,
  };
}
