import React, { useEffect } from 'react';
import Button from 'components/Button/Button';
import { Auth } from 'aws-amplify';
import { clearLocalStorageAndCookies } from 'utils/utils';

export default function SignoutPage() {
  function handleSignIn() {
    Auth.federatedSignIn();
  }

  useEffect(() => {
    clearLocalStorageAndCookies();
    console.log('user signed out');
  }, []);

  return (
    <main className="u-flex-center">
      <div className="container">
        <h1>Your session has expired.</h1>
        <p>Please sign in again.</p>
        <br />
        <Button onClick={handleSignIn}>Sign In</Button>
      </div>
    </main>
  );
}
