import { useContext, useEffect } from 'react';
import { sub, isAfter } from 'date-fns';
import useRefreshToken from 'hooks/useAuthRefresh.hook';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getAccessToken, getJWTTokenExpiryTime } from 'utils/utils';
import config from 'react-global-configuration';

const AmplifyRefreshTimer = (): null => {
  const { authState } = useContext(AuthenticationContext);
  const refreshToken = useRefreshToken();
  const checkInterval = 5000;

  useEffect(() => {
    // initiate interval to check current access tokens expiry,
    // set the timeToNextRefresh to be 1 minute before this expiry time.
    // If the time NOW (when interval iteration is run) is after that time, we refresh the token.
    // This will set a new token, with a new exp time, and re-render this useEffect using authState as a dep.
    // this interval will ensure application continiously checks and repeats whilst application is running.
    // ensuring user stays seamlessly logged in, and authenticated for as long as refresh token lets us.
    if (!config.get().SHOULD_USE_COGNITO) {
      return;
    }
    const accessToken = getAccessToken(authState);
    const accessTokenExpTime = getJWTTokenExpiryTime(accessToken);
    const timeToNextRefresh = sub(accessTokenExpTime, { minutes: 1 });
    let amplifyRefreshIntervalTimer: any = null;

    amplifyRefreshIntervalTimer = setInterval(() => {
      const timeNow = new Date();

      if (isAfter(timeNow, timeToNextRefresh)) {
        refreshToken();
      }
    }, checkInterval);

    return () => {
      clearInterval(amplifyRefreshIntervalTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return null;
};

export default AmplifyRefreshTimer;
