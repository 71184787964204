import { FullConfigContext } from 'contexts/fullConfig.context';
import React, { useContext } from 'react';

import { assetEstimateIsNotFresh, getValueSafely } from 'utils/utils';

export default function DynamicFreshCell({ timestamp, value }: { timestamp: string; value: any }) {
  const { freshPeriodThresholdSeconds } = useContext(FullConfigContext);

  // cell to return value in different colour if it is after the threshold time.
  return assetEstimateIsNotFresh(timestamp, freshPeriodThresholdSeconds) && value ? (
    <div
      className="u-no-pointer-event"
      style={{
        textTransform: 'capitalize',
      }}
    >
      {value === 'unknown' ? '––' : value}
    </div>
  ) : (
    <div className="u-no-pointer-event" style={{ textTransform: 'capitalize' }}>
      {getValueSafely(value === 'unknown' ? '––' : value)}
    </div>
  );
}
