import { shimmerAfterCSS } from 'components/Loading/styled';
import { MOBILEDEVICEWIDTH, SHORTVIEWPORTHEIGHT, TABLETDEVICEWIDTH } from 'config/constants';
import styled from 'styled-components';
import { colours, coloursNames } from 'styling/colours';
import closeIcon from './icons/close.svg';
import bigX from './icons/bigX.svg';
import ReactSlidingPane from 'react-sliding-pane';

const headerBreakpoint = '1425px';
const arrowRightOffset = '-28px';

export const StyledMapWrapper = styled.div`
  height: calc(100vh - 30vh);
  .leaflet-container {
    height: 100%;
  }
  @media (max-width: ${TABLETDEVICEWIDTH}px) {
    height: calc(68vh);
    height: calc(var(--vh, 1vh) * 72); // fix using JS to declare --var programatically.
    overflow-y: hidden;
  }
`;
export const SearchBarContainerStyled = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${TABLETDEVICEWIDTH}px) {
    margin-bottom: initial;
  }
  @media (max-width: ${headerBreakpoint}) {
    flex-direction: column;
  }
`;

export const SearchBarStyled = styled.div`
  width: 50%;
  background: ${colours.disableSelect};
  padding: 0 15px;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  height: 60px;
  &:first-of-type {
    margin-right: 4px;
  }
  &:last-of-type {
    margin-bottom: 22px;
  }
  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    height: auto;
    padding: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${headerBreakpoint}) {
    width: 100%;
    &:first-of-type {
      margin-right: 0px;
      margin-bottom: 0px;
    }

    &:nth-child(2) {
      margin-top: 5px;
    }
  }

  div.test-element {
    max-width: 306px;
    width: 100%;
    margin-left: 10px;
    @media (max-width: ${headerBreakpoint}) {
      &:first-of-type {
        margin-left: 0;
      }
    }
    @media (max-width: ${MOBILEDEVICEWIDTH}px) {
      margin: 10px 0;
      max-width: 100%;
    }
  }
  .react-select-container {
    width: 100%;
    margin-right: 10px;

    .react-select__control {
      justify-content: space-between !important;
      padding: 0 3px 0 10px;
      border-radius: 6px;
      height: 40px;
    }

    .react-select__menu {
      z-index: 9999;
      padding: 16px;
      margin: 0;
      margin-top: 5px;
      max-height: 320px;
      overflow-y: auto;
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        .ant-checkbox-group-item {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        span {
          font-size: 15px;
          text-transform: capitalize;
        }
      }
    }
    &.asset-people-input {
      max-width: 230px;
      margin-right: 0;
    }
    &.asset-subtype-input {
      .react-select__menu {
        z-index: 9999999;
      }
    }
  }
`;

export const VerticleDividerStyled = styled.div`
  width: 5px;
  background: white;
  height: 100%;
  margin: 0 15px;
`;

export const StyledMapDropdownControlWrapper = styled.div`
  .react-select__control {
    padding-left: 16px;
    cursor: pointer;

    .react-select__input-container {
      // hide input so user cant see it.
      opacity: 0;
      pointer-events: none;
    }
    .react-control__preplaceholder {
      color: ${coloursNames.midGrey};
      margin-right: 5px;
    }
    .react-select__value-container {
      padding-left: 2px;
    }
    .react-select__single-value {
      overflow: initial;
    }
    .react-select__indicators {
      button {
        background: none;
        outline: none;
        border: none;
        padding: 0;
        cursor: pointer;
      }
    }
  }
`;

export const StyledDropDownContainer = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    flex-direction: column;
  }
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  min-height: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bar {
    height: 10px;
    width: 5px;
  }
  p {
    margin-top: 30px;
    font-size: 12px;
  }
`;

export const StyledMapFilterContainer = styled.div`
  position: relative;
  border: 1px solid rgba(205, 208, 227, 0.5);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 40px;
  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    border: none;
    margin-bottom: 0px;
  }
`;

export const StyledUserNameTooltipInner = styled.div`
  border-radius: 6px;
  background: #7f8285;
  min-width: 55px;
  padding: 10px;
  span {
    font-family: 'MuseoSans';
    font-size: 15px;
    display: block;
    color: #d8d8d8;
    &:first-of-type {
      color: white;
    }
  }
  &:before {
    // override for arrow.
    left: initial;
    right: -27px;
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-left-color: #7f8285;
    border-width: 15px;
    margin-top: -15px;
  }
`;

export const StyledSmallTooltipInner = styled.div`
  border-radius: 6px;
  background: ${coloursNames.darkGrey};
  height: 65px;

  padding: 10px;
  span {
    font-family: 'MuseoSans';
    font-size: 14px;
    display: block;
    color: #d8d8d8;
    &:first-of-type {
      color: white;
    }
  }
  &:before {
    // override for arrow.
    right: ${arrowRightOffset};
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-left-color: ${coloursNames.darkGrey};
    border-width: 15px;
    margin-top: -15px;
  }
`;

export const StyledBigTooltipInner = styled.div`
  position: relative;
  height: 100%;
  width: 413px;
  border-radius: 6px;
  background: ${coloursNames.darkGrey};
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.122214);
  img.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .tooltip-inner-container {
    max-height: 487px;
    overflow-y: auto;
    width: 100%;
  }
  .tooltip-inner-content {
    padding: 15px 20px 10px 20px;
    .information-line {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 3px;
      white-space: pre-line;
      word-break: break-word;
      h4 {
        width: 35%;
        display: block;
        color: #cbcbcb;
        font-size: 14px;
        font-family: 'MuseoSans';
        margin: 0;
      }
      > span {
        display: flex;
        align-items: center;
        width: 65%;
        display: block;
        font-family: 'MuseoSans';
        font-size: 14px;
        color: white;
      }
      p {
        margin: 0;
      }
    }
    .information-sentance {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      p {
        color: #cccccb;
        font-family: 'MuseoSans';
        font-size: 12px;
        margin: 0;
        padding-left: 10px;
      }
    }
  }

  .hr {
    background: #cbcbcb;
    height: 1px;
    width: 100%;
    margin-top: 10px;
  }
  &:before {
    // override for arrow.
    right: 99.2%;
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-right-color: ${coloursNames.darkGrey};
    border-width: 15px;
    margin-top: -15px;
  }
  // scrollbar overrides
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: ${coloursNames.darkGrey};
  }

  *::-webkit-scrollbar-thumb {
    background-color: #747474;
    border-radius: 20px;
    border: 3px solid ${coloursNames.darkGrey};
  }
`;

export const StyledAssetTotalTooltip = styled.div`
  background: #808285;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: 'MuseoSans';
  font-size: 14px;
  color: white;
`;
export const StyledUserTotalTooltip = styled.div`
  background: #0051bd;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: 'MuseoSans';
  font-size: 14px;
  color: white;
`;

export const StyledLoadingOverlayDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: #00000042;
  cursor: not-allowed;
  .content-wrapper {
    margin: 0;
    background: white;
    max-width: 135px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
  }
`;

export const NoSearchResultsPanelStyledDiv = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -44px;
  background: white;
  width: 100%;
  height: 40px;
  padding: 0 9px;
  z-index: 9999;
  border: 1px solid rgba(188, 190, 192, 0.3);
  border-radius: 3px;
  overflow: hidden;

  span {
    padding-left: 5px;
    color: ${coloursNames.cinnabarOrange};
  }
`;

export const NoFilterResultsPanelStyledDiv = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -39px;
  background: white;
  width: 100%;
  height: 40px;
  padding: 0 9px;
  z-index: 9991;
  border: 1px solid rgba(188, 190, 192, 0.3);
  border-radius: 3px;
  overflow: hidden;

  span {
    padding-left: 5px;
    color: ${coloursNames.cinnabarOrange};
  }
`;
export const StyledFloorSelectorControlDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 8px;
  top: 100px;
  z-index: 999;
  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    top: 10px;
  }
  .button-container {
    position: relative;
    display: block;

    button {
      outline: none;
      background: white;
      border: none;
      width: 34px;
      height: 34px;
      font-size: 12px;
      font-family: 'MuseoSans';
      cursor: pointer;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);

      &.--active {
        background: #808285;
        color: white;
      }

      span.count-readout {
        width: 16px;
        height: 16px;
        position: absolute;
        right: -8px;
        top: 8px;
        background: white;
        border-radius: 50%;
        border: 1px solid #808285;
        color: #414042;
        font-size: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
      }
    }
    &:first-of-type {
      button {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
    &:last-of-type {
      button {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
`;

export const StyledVenueDropdownContainer = styled.div`
  max-width: 250px;
  margin: 20px 0px 0px 25px;
  background: none;
  background-color: transparent;

  .react-select-container {
    z-index: 999;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    border-radius: 6px;
    .react-select__control {
      border-radius: 6px;
      height: 40px;
      padding: 0 0 0 12px;
      font-family: 'MuseoSans';
      .react-select__value-container {
        padding: 2px 3px;
      }
      .react-select__single-value {
        overflow: hidden;
      }
    }
    .react-select__menu {
      border-radius: 3px;
    }
    .react-select__option {
      z-index: 1;
    }
  }
  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    margin: 10px 0 0 10px;
  }
`;

// loading map placeholder screen -------------------
export const StyledMapPlaceholderContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
`;

export const StyledHeaderPlaceholder = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 60px;
  background-color: #f0f1f3;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  &:after {
    ${shimmerAfterCSS}
  }
`;

export const StyledMapPlaceholder = styled.div`
  border: 1px solid rgba(205, 208, 227, 0.5);
  height: calc(100vh - 20vh);
  width: 100%;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    margin-bottom: 0;
  }
  &:after {
    ${shimmerAfterCSS}
  }
`;
export const StyledMapHeaderPlaceholder = styled.div`
  height: 73px;
  background: white;
  width: 100%;
  z-index: 1;
`;

export const StyledLoadingOverlayPlaceholder = styled.div`
  background-color: #f0f1f3;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;

  .content-wrapper {
    margin: 0;
    background: white;
    max-width: 135px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;

    span {
      font-size: 0.75rem;
    }
  }
`;

export const StyledMobileLegendButton = styled.button`
  position: absolute;
  bottom: 30px;
  right: 10px;
  z-index: 999;
  background: white;
  outline: none;
  border: none;
  padding: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #d5d5d5;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 4px 4px;
  &:hover {
    cursor: button;
  }

  @media (max-height: ${SHORTVIEWPORTHEIGHT}px) and (min-width: ${MOBILEDEVICEWIDTH}px) {
    right: 50px;
  }
`;

export const StyledKeyLegend = styled.div`
  display: flex;
  align-items: center;
  width: 195px;
  padding: 10px 0 5px 0;
  background: white;
  position: absolute;
  bottom: 20px;
  right: 10px;
  z-index: 999;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
  ul {
    width: 100%;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      margin: 5px 12px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  .hr {
    height: 1px;
    margin: 10px 0;
    background: #e8e8e8;
    width: 100%;
  }
  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    bottom: 30px;
  }
  @media (max-height: ${SHORTVIEWPORTHEIGHT}px) and (min-width: ${MOBILEDEVICEWIDTH}px) {
    right: 50px;
  }
`;
export const StyledKeyLegendMobileCloseButton = styled.button`
  background: none;
  background-image: url(${closeIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 5px;
  right: 5px;
  outline: none;
  border: none;
  padding: 0;
  height: 20px;
  width: 20px;
`;

export const StyledMobileAssetInfoFlyout = styled(ReactSlidingPane)`
  width: 100%;
  background: white;

  .information-sentance {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    margin-bottom: 20px;
    img {
      margin-right: 5px;
      width: 14px;
      height: 14px;
    }
    p {
      color: #808285;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
  & .slide-pane__header {
    background: ${colours.assetDetailsFlyoutHeader};
    position: relative;
    padding: 35px 0;
    height: 80px;
  }
  & .slide-pane__content {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }
  .hr {
    width: 100%;
    background: #62626233;
    height: 1px;
    margin: 40px auto;
    padding: 0.5px 0;
  }
  & .slide-pane__header * {
    overflow-x: ellipsis;
  }
  & .slide-pane__close {
    position: absolute;
    right: 20px;
    top: 25px;
    background: url(${bigX}) 0 0 no-repeat;
    background-size: 16px 16px;
    background-position-x: center;
    background-position-y: center;
    opacity: 100%;
  }
  & .slide-pane__close svg {
    display: none;
  }
  & h2.slide-pane__title {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0px;
    color: ${colours.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .slide-pane__subtitle {
    color: ${colours.white};
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: ${MOBILEDEVICEWIDTH}px) {
    width: 100% !important;

    h2.slide-pane__title {
      font-size: 18px;
    }
    .slide-pane__close {
      right: 15px;
      top: 15px;
    }
    .hr {
      margin: 20px auto;
    }
    .region {
      margin-left: 50px;
    }
  }
`;

export const StyledMobileAssetsPeopleCheckBoxGroup = styled.div`
  justify-content: flex-start;
  display: flex;
  width: 100%;
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;

    label:first-of-type {
      margin-bottom: 20px;
    }
  }
`;
