import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import useFloorSelectionLogicHooks from './useFloorSelectionLogicHooks';
import useSubscribeToGlobalContext from './useSubscribeToGlobalContext';
import useDebouncedEffect from 'use-debounced-effect';
import {
  checkFullAssetURLParamsExist,
  checkFullMobileClickThroughParamsExist,
} from '../utils/urlParamUtils';

export default function useFloorSelectionEffects() {
  // this hook returns null, but the useEffects listen for when floor change should happen. It is invoked in Layers Controller.
  // using useLayoutEffect for perf reasons, seems to reduce map lag

  const { buildingSelectedID, pollAssetsFinished, shouldRecalculateFloor, apiCallInProgress } =
    useContext(MapControlContext);
  const { searchAPIcallInProgress, doNotCalculateFloor, setDoNotCalculateFloor } =
    useContext(SearchFilterContext);
  const { checkGlobalCTXValueExists } = useSubscribeToGlobalContext();

  const {
    calculateFloorOrdinalAndIDArrayFromGlobalCTX,
    calculateFloorOrdinalAndIDArray,
    calculateFloorOrdinalAndIDArrayFromURLParam,
  } = useFloorSelectionLogicHooks();

  const initialMountFloorOrdinalSet = useRef(false); // a flag to keep track of first time floor is set.

  useDebouncedEffect(
    () => {
      // A DEBOUNCED useEffect for all FLOOR CHANGING LOGIC
      // to run when map initially loads, and when building changes, after API call from search or filter.
      // note we do not want this to run after a search has cleared hence the !doNotCalculateFloor

      if (buildingSelectedID && !apiCallInProgress && !doNotCalculateFloor) {
        // First we check if we need to set floor from Global Context, if user is returning to map from another page,
        // or from URL prarams if user has used click through from asset list.
        const globalCTXHasFloorOrdinal = checkGlobalCTXValueExists('GLOBAL_mapFloorOrdinal');
        const shouldUseGlobalFloorOrdinal =
          !initialMountFloorOrdinalSet.current && globalCTXHasFloorOrdinal;
        const urlParamsExist =
          checkFullAssetURLParamsExist() || checkFullMobileClickThroughParamsExist();
        const shouldUseParamsOrdinal = urlParamsExist && !initialMountFloorOrdinalSet.current;

        if (shouldUseParamsOrdinal) {
          calculateFloorOrdinalAndIDArrayFromURLParam();
        } else if (shouldUseGlobalFloorOrdinal) {
          calculateFloorOrdinalAndIDArrayFromGlobalCTX();
        } else {
          calculateFloorOrdinalAndIDArray(); // otherwise run for every other condition.
        }
        // this condition is only allowed to run once after the map mounts by setting initialMountFloorOrdinalSet to true
        initialMountFloorOrdinalSet.current = true;
      }
    },
    {
      timeout: 200,
      ignoreInitialCall: false,
    },
    [buildingSelectedID, apiCallInProgress, searchAPIcallInProgress],
  );

  useEffect(() => {
    // Useeffect to set doNotCalclateFloor to false after a delay & after API call has finished after clearing search.
    // Slight work around to prevent useffect above running after clearing a search

    if (doNotCalculateFloor) {
      if (!apiCallInProgress) {
        setTimeout(() => {
          setDoNotCalculateFloor(false);
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCallInProgress]);

  useLayoutEffect(() => {
    // A useEffect for all FLOOR CHANGING LOGIC after a POLL occurs
    // currently shouldRecalculateFloor is only true if there is a single returned from a search and its location has changed.
    if (buildingSelectedID && pollAssetsFinished && shouldRecalculateFloor) {
      calculateFloorOrdinalAndIDArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollAssetsFinished]);

  return null;
}
