import React, { useContext } from 'react';
import assetLocationIcon from '../icons/asset-location-icon.svg';
import staleIcon from '../icons/stale-asset.svg';
import personIcon from '../icons/person-icon.svg';
import { PERMISSIONS } from 'config/permissions';
import ComponentFeaturePermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentFeaturePermissionRenderWrapper';
import { FullConfigContext } from 'contexts/fullConfig.context';

export default function KeyLegendPanelContents() {
  const { userTrackingPermissionEnabled, assetTrackingPermissionEnabled } =
    useContext(FullConfigContext);

  const userHasFullPermission = userTrackingPermissionEnabled && assetTrackingPermissionEnabled;

  return (
    <ul>
      <ComponentFeaturePermissionRenderWrapper
        featurePermissions={PERMISSIONS.FEATURE.ASSET_TRACKING}
      >
        <>
          <li>
            <img src={staleIcon} alt="old Estimate" />
            <span>Old estimate</span>
          </li>
          <li>
            <img src={assetLocationIcon} alt="Asset Location" />
            <span>Asset Location</span>
          </li>
        </>
      </ComponentFeaturePermissionRenderWrapper>

      {userHasFullPermission && <div className="hr" />}

      <ComponentFeaturePermissionRenderWrapper
        featurePermissions={PERMISSIONS.FEATURE.USER_TRACKING}
      >
        <li>
          <img src={personIcon} alt="Person" />
          <span>Phone-user</span>
        </li>
      </ComponentFeaturePermissionRenderWrapper>
    </ul>
  );
}
