import React, {
  useEffect,
  createContext,
  ReactNode,
  useState,
  useContext,
  useReducer,
} from 'react';
import { getPermissionsArrayFromObject, getIdToken, getBeaconIntergration } from 'utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import { AuthenticationContext } from './authentication.context';
import LoadingPlaceholderSkeletonPage from 'components/Loading/LoadingPlaceholderPage/LoadingPlaceholderSkeletonPage';
import ConfigApi from 'api/config/config.api';
import { fullConfigType } from 'types/config';
import { PERMISSIONS } from 'config/permissions';
import { VENUE_API_ENDPOINT } from 'config/constants';
import configReducer from 'reducers/configReducer';
import config from 'react-global-configuration';
import { useErrorBoundary } from 'react-error-boundary';

export interface FullConfigContextI {
  userFeaturePermissionsArray: string[];
  userAccessPermissionsArray: string[];
  assetTrackingPermissionEnabled: boolean;
  userTrackingPermissionEnabled: boolean;
  hasBeaconIntegrations: boolean;
  beaconIntegration: string;
  userAccessPermissions: { hasRead: boolean; hasWrite: boolean; hasAdmin: boolean };
  freshPeriodThresholdSeconds: number;
  version: string;
  runPendo: boolean;
}

export const FullConfigContext = createContext({} as FullConfigContextI);
type FullConfigProviderProps = { children: ReactNode };

// context to provide full applications private config for web.
const FullConfigProvider = ({ children }: FullConfigProviderProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [configSet, setConfigSet] = useState(false);
  const [freshPeriodThresholdSeconds, setfreshPeriodThresholdSeconds] = useState<number>(600); // 10 mins as default
  const [userFeaturePermissionsArray, setUserFeaturePermissionsArray] = useState<string[] | []>([]);
  const [userAccessPermissionsArray, setUserAccessPermissionsArray] = useState<string[] | []>([]);
  const [version, setVersion] = useState('');
  const [assetTrackingPermissionEnabled, setAssetTrackingPermissionEnabled] = useState(false);
  const [userTrackingPermissionEnabled, setUserTrackingPermissionEnabled] = useState(false);
  const [hasBeaconIntegrations, setHasBeaconIntegrations] = useState(false);
  const [beaconIntegration, setBeaconIntegration] = useState('');
  const [userAccessPermissions, setUserAccessPermissions] = useState({
    hasRead: false,
    hasWrite: false,
    hasAdmin: false,
  });
  const [runPendo, setRunPendo] = useState(false);

  const configState = config.get();

  const { authState } = useContext(AuthenticationContext);
  const [ilsConfig, dispatch] = useReducer(configReducer, configState);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const accessToken = getIdToken(authState);
    const configApi = new ConfigApi(accessToken);

    configApi
      .getFullWebConfig()
      .then((res: fullConfigType) => {
        const hasBeaconIntegrations = getBeaconIntergration(res.featureset.integrations) !== '';
        const beaconIntegration = getBeaconIntergration(res.featureset.integrations);
        const availableFeaturesArray = getPermissionsArrayFromObject(res.featureset);
        const configSystemData = {
          HELP_URL: res.services.help_url,
          VENUE_API_URL: `${res.services.venue_service}${VENUE_API_ENDPOINT}`,
        };

        const userAccessPermissions = {
          hasRead: res.permissions.items.includes(PERMISSIONS.USER.READ),
          hasWrite: res.permissions.items.includes(PERMISSIONS.USER.WRITE),
          hasAdmin: res.permissions.items.includes(PERMISSIONS.USER.ADMIN),
        };

        setHasBeaconIntegrations(hasBeaconIntegrations);
        setBeaconIntegration(beaconIntegration);
        setUserFeaturePermissionsArray(availableFeaturesArray);
        setAssetTrackingPermissionEnabled(
          availableFeaturesArray.includes(PERMISSIONS.FEATURE.ASSET_TRACKING),
        );
        setUserTrackingPermissionEnabled(
          availableFeaturesArray.includes(PERMISSIONS.FEATURE.USER_TRACKING),
        );
        setUserAccessPermissions(userAccessPermissions);
        setUserAccessPermissionsArray(res.permissions.items);
        setfreshPeriodThresholdSeconds(res.ui.map_element.color_policy.fresh_period_threshold);
        setRunPendo(res.run_pendo);

        dispatch({ type: 'SYSTEM_SERVICES', data: configSystemData });
        setVersion(res.version.version);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        alertErrorMessage('Error getting config features');
        showBoundary(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoaded) {
      config.set(ilsConfig);
      setConfigSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return configSet ? (
    <FullConfigContext.Provider
      value={{
        userFeaturePermissionsArray,
        userAccessPermissionsArray,
        assetTrackingPermissionEnabled,
        userTrackingPermissionEnabled,
        hasBeaconIntegrations,
        beaconIntegration,
        userAccessPermissions,
        freshPeriodThresholdSeconds,
        version,
        runPendo,
      }}
    >
      {children}
    </FullConfigContext.Provider>
  ) : (
    <LoadingPlaceholderSkeletonPage />
  );
};

export default FullConfigProvider;
