import usePageInfo from 'hooks/pageInfo.hook';
import React, { useEffect } from 'react';

import { routesType } from './routes';

const PreRoute = ({ children, route }: { children: React.ReactElement; route: routesType }) => {
  const { setContextPageTitle } = usePageInfo();

  useEffect(() => {
    // useEffect to execute various functions on route change.
    window.scrollTo(0, 0);
    document.querySelector('body')?.setAttribute('data-page', route.url);
    setContextPageTitle(route.title || '');

    return () => {
      document.querySelector('body')?.removeAttribute('data-page');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.url]);

  return children;
};

export default PreRoute;
