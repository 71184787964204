import LoadingBars from 'components/Loading/LoadingBars';
import React from 'react';
import {
  StyledHeaderPlaceholder,
  StyledLoadingOverlayPlaceholder,
  StyledMapHeaderPlaceholder,
  StyledMapPlaceholder,
  StyledMapPlaceholderContainer,
} from '../Styled';
import useDevice from 'hooks/useDevice';

export default function MapPlaceholderLoadingScreen() {
  const { isTabletDevice } = useDevice();
  return (
    <StyledMapPlaceholderContainer>
      {!isTabletDevice && <StyledHeaderPlaceholder />}
      {!isTabletDevice && (
        <StyledHeaderPlaceholder style={{ marginTop: '5px', marginBottom: '22px' }} />
      )}
      <StyledMapPlaceholder>
        {!isTabletDevice && <StyledMapHeaderPlaceholder />}
        <StyledLoadingOverlayPlaceholder>
          <div className="content-wrapper">
            <LoadingBars barHeight={10} barWidth={3} />
            <span>Loading...</span>
          </div>
        </StyledLoadingOverlayPlaceholder>
      </StyledMapPlaceholder>
    </StyledMapPlaceholderContainer>
  );
}
