import UserApi from 'api/user/user.api';
import { UsersContext } from '../contexts/users.context';
import { useContext } from 'react';
import { getIdToken, getUsersActiveLastHour } from 'utils/utils';
import { getOutoorUserEstimates, getIndoorUsersWithEstimate } from '../utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import { VenuesContext } from '../contexts/venues.context';

export default function useCallUsersAPIIntervalFunction() {
  const { setUsersMarkers, setUsersOutsideMappedBuildingsMarkers } = useContext(UsersContext);
  const { selectedVenueObj } = useContext(VenuesContext);

  function callUsersAPIIntervalFunction() {
    // using localstorage for token instead of context, as we need the latest token each time, and cant use usecontext inside the interval function.
    const localStorageState: any = localStorage.getItem('aws-amplify-cacheauthState');
    const authState = JSON.parse(localStorageState).data;
    const token = getIdToken(authState);
    const userApi = new UserApi(token);

    userApi
      .getUsersMap(selectedVenueObj.venue_id)
      .then((res) => {
        const { items } = res.data;
        const activeUsers = getUsersActiveLastHour(items);
        const filteredItems = getIndoorUsersWithEstimate(activeUsers);
        const outdoorFilteredItems = getOutoorUserEstimates(activeUsers);

        setUsersOutsideMappedBuildingsMarkers(outdoorFilteredItems);
        setUsersMarkers(filteredItems);
      })
      .catch((err) => {
        alertErrorMessage('Could not fetch Users');
        console.error(err.message);
      });
  }
  return { callUsersAPIIntervalFunction };
}
