import { useMemo, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import config from 'react-global-configuration';
import { LocalAuth } from 'utils/_localAuth';

export type AuthStateTypes = {
  isMock?: boolean;
  isAuthenticated: boolean;
  Session: any;
  authenticationFlowType: string;
  client: any;
  keyPrefix: string;
  pool: any;
  signInUserSession: any;
  storage: any;
  userDataKey: string;
  username: string;
};
export type AuthContextTypes = {
  authState: AuthStateTypes;
  setAuthState: any;
};

export const defaultAuthState = {
  isAuthenticated: false,
  Session: null,
  authenticationFlowType: '',
  client: null,
  keyPrefix: '',
  pool: null,
  signInUserSession: null,
  storage: null,
  userDataKey: '',
  username: '',
};
//  use localstorage instead of cache, as cache wasnt working correctly with Cypress.
const authStateLocalStorage = JSON.parse(
  localStorage.getItem('aws-amplify-cacheauthState') || '{}',
);
// check if auth exists in localstorage first and use that, otherwise use default.
const initialAuthState = authStateLocalStorage.data ? authStateLocalStorage.data : defaultAuthState;

const useAuth = () => {
  const [authState, setAuthState] = useState<AuthStateTypes>(initialAuthState);
  const navigate = useNavigate();

  const auth: any = useMemo(() => {
    if (config.get().SHOULD_USE_COGNITO) {
      return Auth;
    } else {
      return new LocalAuth(navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authSignout = async () => {
    await auth.signOut().then(() => {
      setAuthState(defaultAuthState);
      // redirect to signout
      navigate('/signout');
    });
  };

  return {
    auth,
    authState,
    defaultAuthState,
    setAuthState,
    authSignout,
  };
};

export default useAuth;
