import { getWindowURL } from './utils';

function getAmplifyConfig(config: any, signoutURL?: string | null) {
  const CONFIG = config.get();

  return {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      // region: window.CONFIG.AWS_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: CONFIG.COGNITO_USERPOOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: CONFIG.COGNITO_CLIENT_ID,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: CONFIG.COGNITO_USERFLOW_TYPE,
      // OPTIONAL - Hosted UI CONFIGuration
      oauth: {
        domain: CONFIG.COGNITO_DOMAIN,
        scope: ['openid', 'profile'],
        redirectSignIn: `${getWindowURL()}/authentication/callback`,
        redirectSignOut: signoutURL ? signoutURL : `${getWindowURL()}/signout`,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
      // (optional) - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // - Cookie domain (only required if cookieStorage is provided)
        domain: `${window.location.hostname}`,
        // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'none',
        // (optional) - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
      },
    },
  };
}
export default getAmplifyConfig;
