import useAuth from 'hooks/useAuth.hook';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { AuthContextTypes } from 'hooks/useAuth.hook';
import { Cache } from 'aws-amplify';
import LoadingPlaceholderSkeletonPage from 'components/Loading/LoadingPlaceholderPage/LoadingPlaceholderSkeletonPage';

export const AuthenticationContext = createContext({} as AuthContextTypes);
type AuthenticationProviderProps = { children: ReactNode };

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [userAuthChecked, setUserAuthChecked] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { auth, authState, setAuthState } = useAuth();

  const value: any = { authState, setAuthState };

  function handleAuthError(e: any) {
    // if current user does not exist or errors, take user directly to signin.

    setIsAuthenticating(false);
    setUserAuthChecked(false);
    console.log('Authentication Error:', e);
    console.log('Authentication: [redirecting to federated signin]');
    auth.federatedSignIn();
  }

  async function checkCurrentSession() {
    try {
      const { accessToken } = await auth.currentSession();

      if (accessToken) {
        setUserAuthChecked(true);
        setIsAuthenticating(false);
      }
    } catch (err) {
      handleAuthError(err);
    }
  }

  useEffect(() => {
    // a function to get current authenticated user, and set auth state with cognito auth
    // if this check errors, it means they are not authenticated and we redirect them to the signin page.
    // this will run once, when the application loads after returning from cognito sign in ui.
    // we bypass this if mocking authstate for tests.

    if (!authState.isMock) {
      setIsAuthenticating(true);
      checkCurrentSession().then(() => {
        auth
          .currentAuthenticatedUser()
          .then((user: any) => {
            const newState = { ...user, isAuthenticated: true };

            setAuthState(newState);
            Cache.setItem('authState', newState);
            setIsAuthenticating(false);
            setUserAuthChecked(true);
          })
          .catch((e: any) => {
            handleAuthError(e);
          });
      });
    } else {
      setIsAuthenticating(false);
      setUserAuthChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userAuthChecked && !isAuthenticating ? (
    <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
  ) : (
    <LoadingPlaceholderSkeletonPage />
  );
};

export default AuthenticationProvider;
