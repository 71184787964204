import React from 'react';
import AssetSubTypeDropdownFilterInput from '../components/SearchFilterBar/AssetSubTypeDropdownFilterInput';
import AssetOwnerDropdownFilterInput from '../components/SearchFilterBar/AssetOwnerDropdownFilterInput';
import { StyledDropDownContainer } from '../Styled';

// This component stores query state used to call API for both dropdowns.
// We pass states down, so they can be set and consumed from the api calls in children
export default function DropdownFilterInputController({
  resetSearchFromCheckbox,
}: {
  resetSearchFromCheckbox: () => void;
}) {
  return (
    <StyledDropDownContainer>
      <div className="test-element" data-test-id="asset_type_filter">
        <AssetSubTypeDropdownFilterInput resetSearchFromCheckbox={resetSearchFromCheckbox} />
      </div>
      <div className="test-element" data-test-id="owner_filter">
        <AssetOwnerDropdownFilterInput resetSearchFromCheckbox={resetSearchFromCheckbox} />
      </div>
    </StyledDropDownContainer>
  );
}
