import { useContext } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { Cache } from 'aws-amplify';
import useAuth from './useAuth.hook';
import { windowBeforeUnloadEventHandler } from 'utils/utils';
import useBroadcastChannel from './useBroadcastChannel';

const useAuthRefresh = (): any => {
  const { setAuthState, authState } = useContext(AuthenticationContext);
  const { auth } = useAuth();
  const { postBroadcastMessage } = useBroadcastChannel();

  async function refreshToken() {
    try {
      const cognitoUser = await auth.currentAuthenticatedUser();
      const currentSession: any = await auth.currentSession();

      cognitoUser.refreshSession(currentSession.refreshToken, (err: any) => {
        if (!err) {
          const newState = { ...cognitoUser, isAuthenticated: true };
          const timeNow: any = Date.now();

          setAuthState(newState);
          Cache.setItem('authState', newState);
          console.log(`%c[Auth Token Refreshed]: ${timeNow}`, 'color: lightblue;');
        } else {
          console.log(err);
        }
      });
    } catch (e) {
      // check were not using authState that is being mocked for cypress tests
      if (!authState.isMock) {
        // if refresh token has expired, remove unload event handler that may have been attached when editing forms
        // then sign userout
        window.removeEventListener('beforeunload', windowBeforeUnloadEventHandler, {
          capture: true,
        });
        console.log(e, 'Logging User Out');
        postBroadcastMessage('broadcast_signout');
      }
    }
  }

  return refreshToken;
};

export default useAuthRefresh;
