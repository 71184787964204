import { icons } from 'assets/icons';
import React, { useContext, useState } from 'react';
import PageTitle from 'components/Header/components/PageTitle';
import usePageInfo from 'hooks/pageInfo.hook';
import useDevice from 'hooks/useDevice';

import { Horizontal, StretchSpacer } from 'gls';
import IconButton from 'components/Button/IconButton';
import { AssetListPageContext } from 'contexts/assetListPage.context';
import { isEmpty } from 'lodash';
import { RecentUsersContext } from 'contexts/recentUsers.context';
import UsersSearchField from './UsersSearchField';
import { HeaderStyled, SearchStyleDiv, StyledSubHeading } from 'components/Header/style';
import UserInfoDropdown from 'components/Header/components/UserInfoDropdown/UserInfoDropdown';

type RecentUsersHeaderProps = {
  SearchHandler: (searchTerm: string, searchIn?: string) => void;
};

export const RecentUsersHeader = ({ SearchHandler: SearchHanlder }: RecentUsersHeaderProps) => {
  const { contextPageTitle } = usePageInfo();
  const { totalCount } = useContext(RecentUsersContext);
  const { isTabletDevice, isMobileDevice } = useDevice();
  const [showSearch, setShowSearch] = useState(false);
  const { searchTerm } = useContext(AssetListPageContext);

  const countTitle = (
    <StyledSubHeading data-test-id="assetCount">
      #{totalCount?.toString().padStart(2, '0')} Total User
      {totalCount === 1 ? '' : 's'}
    </StyledSubHeading>
  );

  if (isMobileDevice) {
    return (
      <div className="container">
        {showSearch ? (
          <SearchStyleDiv>
            <div className="search-container">
              <UsersSearchField SearchHanlder={SearchHanlder} />
              {isEmpty(searchTerm) && (
                <IconButton
                  className="close-search"
                  icon={<icons.CloseIcon width="15px" height="15px" />}
                  onClick={() => setShowSearch(false)}
                />
              )}
            </div>
            {countTitle}
          </SearchStyleDiv>
        ) : (
          <HeaderStyled className="page-header">
            <div className="header-item title">
              <Horizontal width="100%">
                <PageTitle pageTitle={contextPageTitle} />
                <StretchSpacer />
                <IconButton icon={<icons.SearchIcon />} onClick={() => setShowSearch(true)} />
              </Horizontal>
              {countTitle}
            </div>
          </HeaderStyled>
        )}
      </div>
    );
  }

  return (
    <div className="container">
      <HeaderStyled className="page-header">
        <div className="header-item title">
          <PageTitle pageTitle={contextPageTitle} />
          {countTitle}
        </div>
        <div className="header-item search">
          <UsersSearchField SearchHanlder={SearchHanlder} />
          {!isTabletDevice && <UserInfoDropdown />}
        </div>
      </HeaderStyled>
    </div>
  );
};
