import React, { useState } from 'react';
import styled from 'styled-components';
import { EstimateType } from 'types/Estimate';
import { getValueSafely } from 'utils/utils';

const StyledDiv: any = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3px;
  font-family: 'MuseoSans';
  font-size: 14px;
  color: white;

  h4 {
    min-width: 126px;
    display: block;
    color: #cbcbcb;
    font-size: 14px;
    font-family: 'MuseoSans';
    margin: 0;
  }
  p {
    margin: 0;
    width: 100%;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
    max-width: 242px;
    &.trimmed {
      position: relative;
      max-height: 40px;
      word-break: break-all;
    }
  }
`;

const ButtonStyledAsLink = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  padding-left: 3px;
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
  color: #bcbec0;
  font-size: 12px;
  font-family: 'MuseoSans';
  background: #414042;

  &:hover {
    cursor: pointer;
  }
`;

export default function TooltipDescriptionPanel({ asset }: { asset: EstimateType }) {
  const [readMoreOpen, setReadMoreOpen] = useState(false);

  const textCutoffLength = 59;
  const assetDescriptionText = getValueSafely(asset.description);
  const requiresReadMoreButton = assetDescriptionText.length >= textCutoffLength;
  const truncatedText = assetDescriptionText.substring(0, textCutoffLength);

  function handleReadmoreClick() {
    setReadMoreOpen(true);
  }

  return (
    <StyledDiv readMoreOpen={readMoreOpen}>
      <h4>Description:</h4>

      {readMoreOpen ? (
        <p>{assetDescriptionText}</p>
      ) : (
        <p className="trimmed">
          {requiresReadMoreButton ? `${truncatedText}...` : assetDescriptionText}
          {requiresReadMoreButton && (
            <ButtonStyledAsLink onClick={handleReadmoreClick}>read more</ButtonStyledAsLink>
          )}
        </p>
      )}
    </StyledDiv>
  );
}
