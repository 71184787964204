import React, { useContext, useEffect } from 'react';
import AuthRefreshTimer from 'components/AuthRefreshTimer';
import useRefreshToken from 'hooks/useAuthRefresh.hook';
import { tokenHasExpired, getIdToken, setVHCustomHeightCSSProperty } from 'utils/utils';
import UserPermissionCheckRouteWrapper from 'wrappers/RouteCheckWrappers/UserPermissionCheckRouteWrapper';
import UserFeatureCheckRouterWrapper from 'wrappers/RouteCheckWrappers/UserFeatureCheckRouterWrapper';
import UserProvider from 'contexts/user.context';
import { AuthenticationContext } from 'contexts/authentication.context';
import AssetConfigProvider from 'contexts/assetConfig.context';
import { routesType } from './routes';
import useBroadcastChannel from 'hooks/useBroadcastChannel';
import VenuesProvider from 'contexts/venues.context';
import FullConfigProvider from 'contexts/fullConfig.context';
import SystemErrorPage from 'views/SystemErrorPage/SystemErrorPage';
import { ErrorBoundary as SystemErrorBoundary } from 'react-error-boundary';

type PrivateRouteType = {
  children: React.ReactNode;
  route: routesType;
};

const PrivateRoute = ({ children, route }: PrivateRouteType) => {
  const { authState } = useContext(AuthenticationContext);
  const token = getIdToken(authState);
  const refreshToken = useRefreshToken();
  const { initialiseBroadcastMessageEventListener } = useBroadcastChannel();

  useEffect(() => {
    // refresh auth token if it doesn't exist or is expired.
    // also, !isMock flag added, because we dont care about this if we're running tests.
    if (tokenHasExpired(token) && !authState.isMock) {
      refreshToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialiseBroadcastMessageEventListener();
    setVHCustomHeightCSSProperty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SystemErrorBoundary fallback={<SystemErrorPage />}>
      <FullConfigProvider>
        <UserFeatureCheckRouterWrapper routeFeaturePermissions={route.routeFeaturePermissions}>
          <AssetConfigProvider>
            <VenuesProvider>
              <UserProvider>
                <UserPermissionCheckRouteWrapper routeUserPermissions={route.routeUserPermissions}>
                  <AuthRefreshTimer />
                  <div className="wrapper">{children}</div>
                </UserPermissionCheckRouteWrapper>
              </UserProvider>
            </VenuesProvider>
          </AssetConfigProvider>
        </UserFeatureCheckRouterWrapper>
      </FullConfigProvider>
    </SystemErrorBoundary>
  );
};

export default PrivateRoute;
