import React, { useContext } from 'react';
import L from 'leaflet';
import transparentImg from '../../icons/transparent.png';
import { Marker, Tooltip } from 'react-leaflet';
import { StyledUserTotalTooltip } from '../../Styled';
import { getUserAssetCountFromFeatureBuildingUUIDArray } from '../../utils/utils';
import { MapControlContext } from '../../contexts/mapcontrol.context';
import { UsersContext } from 'views/MapPage/contexts/users.context';

type UsersLayerType = {
  feature: any;
};
export default function BuildingUsersTotalCountTooltip({ feature }: UsersLayerType) {
  const { usersMarkers } = useContext(UsersContext);
  const { buildingSelectedID } = useContext(MapControlContext);

  const displayPoint: any = [...feature.properties.display_point.coordinates].reverse(); // reversed to correct order
  const buildingIsSelected = feature.properties.building_ids.includes(buildingSelectedID);

  const customIcon = new L.Icon({
    iconUrl: transparentImg, // 1px alpha image to show nothing.
    iconSize: new L.Point(0, 0),
  });

  const usersCount = getUserAssetCountFromFeatureBuildingUUIDArray(
    feature.properties.building_ids,
    usersMarkers,
  );

  // only show tooltip if count is over 0
  // dont show if building is selected.

  return usersCount > 0 && !buildingIsSelected ? (
    <Marker icon={customIcon} position={displayPoint}>
      <Tooltip
        className="tooltip-user-total"
        permanent
        opacity={1}
        direction="bottom"
        offset={[25, 15]}
      >
        <StyledUserTotalTooltip>{usersCount}</StyledUserTotalTooltip>
      </Tooltip>
    </Marker>
  ) : null;
}
