import React, { useContext } from 'react';
import { colours } from 'styling/colours';
import { getCalculatedRSSIValue, getLastSeenString, getValueSafely } from 'utils/utils';
import LastSeenCell from '../../../../components/DataTable/cells/LastSeenCell';
import { VenuesContext } from 'contexts/venues.context';
import { StyledTable } from '../Styled';
import LocationSpan from './LocationSpan';
import AccuracyRangeSpan from './AccuracyRangeSpan';
import { format } from 'date-fns';
import { LocalizedBeaconType } from 'types/BeaconType';
import BatterySpan from './BatterySpan';
import {
  BroadcastProfilesInterface,
  EddystoneProfile,
  iBeaconProfile,
} from 'types/BroadcastProfile';

function getBeaconProfileStringFromObj(broadcastProfileObj: BroadcastProfilesInterface) {
  let arr: any | BroadcastProfilesInterface[] = [];

  Object.keys(broadcastProfileObj).filter((profile: any) => {
    if (profile) {
      arr.push(profile);
    }
  });

  return arr.join(', ');
}

const RegisteredBeaconFlyoutDetails = ({
  selectedBeaconDetails,
}: {
  selectedBeaconDetails: LocalizedBeaconType;
}) => {
  const { isSingleVenue } = useContext(VenuesContext);
  const assetIsOutdoor = selectedBeaconDetails?.location?.is_outdoors;

  const beaconProfiles = getBeaconProfileStringFromObj(selectedBeaconDetails.broadcast_profiles);

  const createdDate = selectedBeaconDetails.import_info.import_timestamp
    ? format(new Date(selectedBeaconDetails.import_info.import_timestamp), 'dd/LL/yyyy')
    : '––';

  const eddystoneProfile: EddystoneProfile = selectedBeaconDetails.broadcast_profiles.eddystone_uid;
  const ibeaconProfile: iBeaconProfile = selectedBeaconDetails.broadcast_profiles.ibeacon;

  return (
    <>
      <StyledTable data-test-id="beacon-details-general">
        <tbody>
          <tr>
            <td>Beacon ID:</td>
            <td>{getValueSafely(selectedBeaconDetails.beacon_id)}</td>
          </tr>
          <tr>
            <td>Beacon Type:</td>
            <td>{getValueSafely(selectedBeaconDetails.beacon_type)}</td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />
      <StyledTable data-test-id="beacon-details-protocol">
        <tbody>
          <tr>
            <td>MAC Address:</td>
            <td>{getValueSafely(eddystoneProfile?.mac_address)}</td>
          </tr>
          <tr>
            <td>Import Date:</td>
            <td>{createdDate}</td>
          </tr>
          <tr>
            <td>Notes:</td>
            <td>{getValueSafely(selectedBeaconDetails.notes)}</td>
          </tr>

          <tr>
            <td>Broadcast Profiles:</td>
            <td>{getValueSafely(beaconProfiles)}</td>
          </tr>
          <tr>
            <td className="profile-title">Eddystone</td>
          </tr>
          <tr>
            <td>Namespace:</td>
            <td>{getValueSafely(eddystoneProfile?.namespace)}</td>
          </tr>
          <tr>
            <td>Instance:</td>
            <td>{getValueSafely(eddystoneProfile?.instance)}</td>
          </tr>
          <tr>
            <td>Transmission Power:</td>
            <td>{getValueSafely(getCalculatedRSSIValue(eddystoneProfile), ' RSSI @0m')}</td>
          </tr>
          <tr>
            <td className="profile-title">iBeacon</td>
          </tr>
          <tr>
            <td>Proximity UUID:</td>
            <td>{getValueSafely(ibeaconProfile?.proximity_uuid)}</td>
          </tr>
          <tr>
            <td>Major:</td>
            <td>{getValueSafely(ibeaconProfile?.major.toString())}</td>
          </tr>
          <tr>
            <td>Minor:</td>
            <td>{getValueSafely(ibeaconProfile?.minor.toString())}</td>
          </tr>
          <tr>
            <td>Transmission Power:</td>
            <td>{getValueSafely(getCalculatedRSSIValue(ibeaconProfile), ' RSSI @1m')}</td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />

      <StyledTable data-test-id="beacon-details-estimate">
        <tbody>
          <tr>
            <td className="profile-title">Beacon estimates:</td>
          </tr>
          <tr>
            <td>Last Seen:</td>
            <td>
              <LastSeenCell timestamp={selectedBeaconDetails.last_location_update_timestamp} />
              {` ${getLastSeenString(selectedBeaconDetails.last_location_update_timestamp)}`}
            </td>
          </tr>
          <tr>
            <td>Accuracy Range:</td>
            <td>
              <AccuracyRangeSpan assetDetails={selectedBeaconDetails} />{' '}
            </td>
          </tr>
          <tr>
            <td>Status:</td>
            <td style={{ textTransform: 'capitalize' }}>
              {selectedBeaconDetails.mobility_state === 'unknown'
                ? '––'
                : selectedBeaconDetails.mobility_state}
            </td>
          </tr>
          <tr>
            <td>Battery:</td>
            <td>
              <BatterySpan beacon={selectedBeaconDetails} />
            </td>
          </tr>
          <tr>
            <td valign="top">Location:</td>
            <td>
              {!isSingleVenue && (
                <div style={{ marginBottom: '15px' }}>
                  {selectedBeaconDetails?.location?.venue?.name}
                </div>
              )}
              {assetIsOutdoor ? (
                <span style={{ marginBottom: '10px' }}>Outside Mapped Buildings</span>
              ) : (
                <LocationSpan assetDetails={selectedBeaconDetails} />
              )}
              <div style={{ color: colours.secondaryText, margin: '15px 0' }}>Possible region:</div>
              <div>{getValueSafely(selectedBeaconDetails?.location?.region?.name)}</div>
            </td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />

      <StyledTable data-test-id="beacon-details-asset">
        <tbody>
          <tr>
            <td className="profile-title">Assigned asset:</td>
          </tr>
          <tr>
            <td>Asset Name:</td>
            <td>{getValueSafely(selectedBeaconDetails.assigned_asset?.name)}</td>
          </tr>
          <tr>
            <td>Asset Type:</td>
            <td>{getValueSafely(selectedBeaconDetails.assigned_asset?.asset_type.name)}</td>
          </tr>
          <tr>
            <td>Asset ID:</td>
            <td>{getValueSafely(selectedBeaconDetails.assigned_asset?.id)}</td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
};
export default RegisteredBeaconFlyoutDetails;
