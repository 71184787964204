import React from 'react';
import { format } from 'date-fns';
import { getLastSeenString, getValueSafely } from 'utils/utils';
import LastSeenCell from '../../../../components/DataTable/cells/LastSeenCell';

import infoImg from '../../icons/infoBg.svg';
import { StyledTable } from 'views/AssetListPage/components/styles';
import AccuracyRangeSpan from 'views/AssetListPage/components/content/AccuracyRangeSpan';
import LocationSection from 'views/AssetListPage/components/content/LocationSection';
import BatteryReadoutSpan from '../InfoBoxSpans/BatteryReadoutSpan';
import { EstimateType } from 'types/Estimate';
import DefaultCell from 'components/DataTable/cells/DefaultCell';

function getCreatedDateString(asset: EstimateType) {
  if (!asset.creation_timestamp) return '––';

  const addedOnDate = asset.creation_timestamp
    ? format(new Date(asset.creation_timestamp), 'dd/LL/yyyy - HH:mm')
    : '––';

  return addedOnDate;
}

const MobileAssetInfoFlyoutContent = ({
  selectedAssetDetails,
}: {
  selectedAssetDetails: EstimateType;
}) => {
  const deviceSource = selectedAssetDetails.assigned_beacon!.import_info.source;

  return (
    <>
      <StyledTable data-test-id="asset-details-id">
        <tbody>
          <tr>
            <td>Asset ID:</td>
            <td>{getValueSafely(selectedAssetDetails.asset_id)}</td>
          </tr>
          <tr>
            <td>Added On:</td>
            <td>{getCreatedDateString(selectedAssetDetails)}</td>
          </tr>
          <tr>
            <td>Owner:</td>
            <td>
              <DefaultCell value={selectedAssetDetails.owner.name} />
            </td>
          </tr>
          <tr>
            <td valign="top">Description:</td>
            <td id="assetDetailsDescription">{getValueSafely(selectedAssetDetails.description)}</td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />
      <div className="information-sentance">
        <img src={infoImg} alt="information" />
        <p>Please note, estimated information was derived from the beacon.</p>
      </div>
      <StyledTable data-test-id="asset-details-estimate">
        <tbody>
          <tr>
            <td>Last Seen:</td>
            <td>
              <LastSeenCell timestamp={selectedAssetDetails.last_location_update_timestamp} />
              {selectedAssetDetails.last_location_update_timestamp &&
                ` ${getLastSeenString(selectedAssetDetails.last_location_update_timestamp)}`}
            </td>
          </tr>
          <tr>
            <td>Accuracy Range:</td>
            <td>
              <AccuracyRangeSpan assetDetails={selectedAssetDetails} />{' '}
            </td>
          </tr>
          <tr>
            <td>Status:</td>
            <td style={{ textTransform: 'capitalize' }}>
              {getValueSafely(selectedAssetDetails.mobility_state)}
            </td>
          </tr>
          <tr>
            <td>Battery:</td>
            <td>
              <BatteryReadoutSpan asset={selectedAssetDetails} darkText />
            </td>
          </tr>
          <tr>
            <td valign="top">Location:</td>
            <td>
              <LocationSection assetDetails={selectedAssetDetails} />
            </td>
          </tr>
          <tr>
            <td>Beacon ID:</td>
            <td>{getValueSafely(selectedAssetDetails.assigned_beacon!.id)}</td>
          </tr>
          <tr>
            <td>Beacon Type:</td>
            <td>
              {getValueSafely(selectedAssetDetails.assigned_beacon!.beacon_type)}
              {deviceSource && ` - ${deviceSource}`}
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
};

export default MobileAssetInfoFlyoutContent;
