import React, { useContext } from 'react';
import { MapContainer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'assets/scss/leaflet-custom.scss';
import { StyledMapWrapper } from '../Styled';
import FloorSelectorControl from '../components/FloorSelectorControl';
import LoadingOverlay from '../components/LoadingOverlay';
import { MapControlContext } from '../contexts/mapcontrol.context';
import LayersController from '../controllers/LayersController';
import VenueSelectDropdownControl from '../components/VenueSelectDropdownControl';
import { VenuesContext } from '../contexts/venues.context';
import { TileLayer } from 'react-leaflet';
import config from '../config';
import { MapContainerRefContext } from '../contexts/mapContainerRef.context';
import useDevice from 'hooks/useDevice';
import LegendController from '../controllers/LegendController';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import L from 'leaflet';

export default function MapContainerWrapper() {
  const { apiCallInProgress, buildingIsSelected } = useContext(MapControlContext);
  const { isSingleVenue } = useContext(VenuesContext);
  const { setMapContainerRef } = useContext(MapContainerRefContext);
  const { levelsFeatures } = useContext(VenueFilesContext);

  const { isMobileDevice } = useDevice();
  const bounds = L.geoJSON(levelsFeatures).getBounds(); // set bounds of map to levelFeature bounds.

  return (
    <StyledMapWrapper>
      <MapContainer
        bounds={bounds}
        maxZoom={config.maxZoom}
        minZoom={config.minZoom}
        zoom={config.zoom}
        scrollWheelZoom={false}
        zoomControl={false}
        ref={setMapContainerRef} // set map ref to usestate in parent to be passed to external components.
      >
        {apiCallInProgress && <LoadingOverlay />}
        {!isSingleVenue && <VenueSelectDropdownControl />}
        {buildingIsSelected && <FloorSelectorControl />}
        {!isMobileDevice && <ZoomControl position="topright" />}
        <LayersController />
        <LegendController />
        <TileLayer
          opacity={0.5}
          zIndex={-1}
          maxNativeZoom={19} // upscale OSM tiles, because they dont give lvl 20.
          maxZoom={config.maxZoom}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </StyledMapWrapper>
  );
}
