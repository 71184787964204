import { FullConfigContext } from 'contexts/fullConfig.context';
import React, { useContext } from 'react';
import { featurePermissionType } from 'types/permissions';
import { getArraysIntersection } from 'utils/utils';

type ComponentFeaturePermissionRenderWrapperType = {
  children: React.ReactElement;
  featurePermissions: featurePermissionType[] | any;
};

// a simple wrapper to detect if the component has the required level of feature permissions to be displayed.
export default function ComponentFeaturePermissionRenderWrapper({
  children,
  featurePermissions,
}: ComponentFeaturePermissionRenderWrapperType) {
  const { userFeaturePermissionsArray } = useContext(FullConfigContext);

  const componentHasPermission =
    getArraysIntersection(userFeaturePermissionsArray, featurePermissions).length !== 0;

  return <> {componentHasPermission ? children : null}</>;
}
