import React from 'react';
import { coloursNames } from 'styling/colours';
import { getValueSafely } from 'utils/utils';
import errorIcon from 'assets/icons/error-2.svg';
import { LOW_BATTERY_THRESHOLD } from 'config/constants';
import { LocalizedBeaconType } from 'types/BeaconType';

export default function BatterySpan({ beacon }: { beacon: LocalizedBeaconType }) {
  if (!beacon.battery) return <>––</>;

  const isBelowThreshold = beacon.battery <= LOW_BATTERY_THRESHOLD;
  const textString = getValueSafely(beacon.battery, '%');
  return (
    <span
      style={
        isBelowThreshold
          ? { color: coloursNames.cinnabarOrange, display: 'flex', alignItems: 'center' }
          : {}
      }
    >
      {textString}
      {isBelowThreshold && <img style={{ marginLeft: '13px' }} src={errorIcon} alt="low battery" />}
    </span>
  );
}
