import { AssetConfigContext } from 'contexts/assetConfig.context';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { getArrayOfValuesFromOptions } from '../utils/utils';

type SearchFilterTypes = {
  doNotCalculateFloor: boolean;
  setDoNotCalculateFloor: (arg: boolean) => void;
  inSearchMode: boolean;
  setInSearchMode: (arg: boolean) => void;
  inFilterMode: boolean;
  setInFilterMode: (arg: boolean) => void;
  searchAPIcallInProgress: boolean;
  setSearchAPIcallInProgress: (arg: boolean) => void;
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  assetOwnerCheckedListArray: [] | string[];
  setAssetOwnerCheckedListArray: (value: any) => void;
  assetSubtypeCheckedListArray: [] | string[];
  setAssetSubtypeCheckedListArray: (value: any) => void;
  setAssetSubtypeCheckAll: (arg: boolean) => void;
  setAssetOwnerCheckAll: (arg: boolean) => void;
  assetSubtypeCheckAll: boolean;
  assetOwnerCheckAll: boolean;
  hasSearchTerm: boolean;
  menuIsOpen: boolean;
  setMenuIsOpen: (arg: boolean) => void;
  assetOwnerFilterModified: boolean;
  setAssetOwnerFilterModified: (arg: boolean) => void;
  assetSubtypeFilterModified: boolean;
  setAssetSubtypeFilterModified: (arg: boolean) => void;
  filtersModified: boolean;
  setFiltersModified: (arg: boolean) => void;
  defaultAssetOwnerCheckedListArray: string[];
  defaultAssetSubtypeCheckedListArray: string[];
};

export const SearchFilterContext = createContext({} as SearchFilterTypes);

type SearchFilterProviderProps = { children: ReactNode };

// A context that stores search and filter params for them to be used by polling components.
const SearchFilterProvider = ({ children }: SearchFilterProviderProps) => {
  const { assetOwners, assetSubtype } = useContext(AssetConfigContext);

  //booleans
  const [doNotCalculateFloor, setDoNotCalculateFloor] = useState(false);
  const [inSearchMode, setInSearchMode] = useState(false);
  const [inFilterMode, setInFilterMode] = useState(false);
  const [searchAPIcallInProgress, setSearchAPIcallInProgress] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filtersModified, setFiltersModified] = useState(false);

  const [assetOwnerCheckedListArray, setAssetOwnerCheckedListArray] = useState([]);
  const [assetOwnerFilterModified, setAssetOwnerFilterModified] = useState(false);
  const [assetSubtypeCheckedListArray, setAssetSubtypeCheckedListArray] = useState([]);
  const [assetSubtypeFilterModified, setAssetSubtypeFilterModified] = useState(false);

  const defaultAssetOwnerCheckedListArray = getArrayOfValuesFromOptions(assetOwners);
  const defaultAssetSubtypeCheckedListArray = getArrayOfValuesFromOptions(assetSubtype);

  const [assetSubtypeCheckAll, setAssetSubtypeCheckAll] = useState(true);
  const [assetOwnerCheckAll, setAssetOwnerCheckAll] = useState(true);

  const hasSearchTerm = searchTerm.length > 0;
  const value: SearchFilterTypes = {
    doNotCalculateFloor,
    setDoNotCalculateFloor,
    inSearchMode,
    setInSearchMode,
    inFilterMode,
    setInFilterMode,
    searchAPIcallInProgress,
    setSearchAPIcallInProgress,
    searchTerm,
    setSearchTerm,
    assetOwnerCheckedListArray,
    setAssetOwnerCheckedListArray,
    assetSubtypeCheckedListArray,
    setAssetSubtypeCheckedListArray,
    assetSubtypeCheckAll,
    setAssetSubtypeCheckAll,
    assetOwnerCheckAll,
    setAssetOwnerCheckAll,
    hasSearchTerm,
    menuIsOpen,
    setMenuIsOpen,
    filtersModified,
    setFiltersModified,
    assetOwnerFilterModified,
    setAssetOwnerFilterModified,
    assetSubtypeFilterModified,
    setAssetSubtypeFilterModified,
    defaultAssetOwnerCheckedListArray,
    defaultAssetSubtypeCheckedListArray,
  };

  return <SearchFilterContext.Provider value={value}>{children}</SearchFilterContext.Provider>;
};

export default SearchFilterProvider;
