/* eslint-disable jsx-a11y/click-events-have-key-events */
import { format } from 'date-fns/esm';
import React from 'react';
import { Tooltip } from 'react-leaflet';
import { StyledBigTooltipInner } from '../../Styled';
import { getValueSafely } from 'utils/utils';
import infoImg from '../../icons/info.svg';
import closeImg from '../../icons/close.svg';
import AccuracyRangeSpan from '../InfoBoxSpans/AccuracyRangeSpan';
import BatteryReadoutSpan from '../InfoBoxSpans/BatteryReadoutSpan';
import LocationSpan from '../InfoBoxSpans/LocationSpan';
import TooltipDescriptionPanel from './TooltipDescriptionPanel';
import LastSeenReadoutSpan from '../InfoBoxSpans/LastSeenReadoutSpan';
import { EstimateType } from 'types/Estimate';
import DefaultCell from 'components/DataTable/cells/DefaultCell';

type AssetMarkerLargeInfoboxTooltipType = {
  asset: EstimateType;
  handleCloseInfoBox: () => void;
};

function getCreatedDateString(asset: EstimateType) {
  if (!asset.creation_timestamp) return '––';

  const addedOnDate = asset.creation_timestamp
    ? format(new Date(asset.creation_timestamp), 'dd/LL/yyyy - HH:mm')
    : '––';

  return addedOnDate;
}

function LargeInfoBoxTooltip({ asset, handleCloseInfoBox }: AssetMarkerLargeInfoboxTooltipType) {
  return (
    <Tooltip
      className="tooltip-large"
      interactive={true}
      direction="right"
      offset={[20, 0]}
      opacity={1}
      permanent
    >
      <StyledBigTooltipInner>
        <img
          alt="close"
          className="close-button"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          src={closeImg}
          onClick={() => {
            handleCloseInfoBox();
          }}
        />
        <div className="tooltip-inner-container">
          <div className="tooltip-inner-content">
            <div className="information-line">
              <h4>Asset Name:</h4>
              <span>{getValueSafely(asset.asset_name)}</span>
            </div>
            <div className="information-line">
              <h4>Asset Type:</h4>
              <span>{<DefaultCell value={asset.asset_type.name} />}</span>
            </div>
            <div className="information-line">
              <h4>Asset ID:</h4>
              <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                {getValueSafely(asset.asset_id)}
              </span>
            </div>
            <div className="information-line">
              <h4>Added On:</h4>
              <span>{getCreatedDateString(asset)}</span>
            </div>
            <div className="information-line">
              <h4>Owner:</h4>
              <span>
                <DefaultCell value={asset.owner.name} />
              </span>
            </div>

            <TooltipDescriptionPanel asset={asset} />
          </div>

          <div className="hr"></div>

          <div className="tooltip-inner-content">
            <div className="information-sentance">
              <img src={infoImg} alt="information" />
              <p>Please note, estimated information was derived from the beacon.</p>
            </div>
            <div className="information-line">
              <h4>Last Seen:</h4>
              <LastSeenReadoutSpan timestamp={asset.last_location_update_timestamp} />
            </div>
            <div className="information-line">
              <h4>Accuracy Range:</h4>
              <AccuracyRangeSpan asset={asset} />
            </div>
            <div className="information-line">
              <h4>Status:</h4>
              <span style={{ textTransform: 'capitalize' }}>
                {asset.mobility_state === 'unknown' ? '––' : asset.mobility_state}
              </span>
            </div>
            <div className="information-line">
              <h4>Battery:</h4>
              <BatteryReadoutSpan asset={asset} />
            </div>
            <div className="information-line">
              <h4>Location:</h4>
              <LocationSpan asset={asset} />
            </div>
            <div className="information-line">
              <h4>Beacon ID:</h4>
              <span>{getValueSafely(asset.assigned_beacon!.id)}</span>
            </div>
            <div className="information-line">
              <h4>Beacon Type:</h4>
              <span>
                {getValueSafely(asset.assigned_beacon!.beacon_type)}
                {asset.assigned_beacon!.import_info.source &&
                  ` - ${asset.assigned_beacon!.import_info.source}`}
              </span>
            </div>
          </div>
        </div>
      </StyledBigTooltipInner>
    </Tooltip>
  );
}

export default React.memo(LargeInfoBoxTooltip);
