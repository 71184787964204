import ReactSlidingPane from 'react-sliding-pane';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import bigXDark from '../../icons/bigXDark.svg';

export const StyledMobileMapFilterPanel = styled(ReactSlidingPane)`
  width: 100% !important;
  padding-bottom: 20px;
  p.searchbar-readout {
    color: grey;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 15px;
    text-align: center;
  }
  .information-sentance {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    margin-bottom: 20px;
    img {
      margin-right: 5px;
      width: 14px;
      height: 14px;
    }
    p {
      color: #808285;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
  & .slide-pane__header {
    background: #fff;
    position: relative;
    flex: none;
    height: 0px;
    border-bottom: none;
  }
  & .slide-pane__content {
    display: flex;
    flex-direction: column;
    padding: 0;
    button {
      margin-top: auto;
      margin-left: auto;
      margin-right: 10px;
    }
  }
  .hr {
    width: 100%;
    background: #62626233;
    height: 1px;
    margin: 40px auto;
    padding: 0.5px 0;
  }
  & .slide-pane__header * {
    overflow-x: ellipsis;
  }
  & .slide-pane__close {
    position: absolute;
    right: 15px;
    top: 8px;
    background: url(${bigXDark}) 0 0 no-repeat;
    background-size: 16px 16px;
    background-position-x: center;
    background-position-y: center;
    opacity: 100%;
    z-index: 9;
  }
  & .slide-pane__close svg {
    display: none;
  }
  & h2.slide-pane__title {
    font-size: 18px;

    line-height: 30px;
    letter-spacing: 0px;
    color: ${colours.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .slide-pane__subtitle {
    color: ${colours.white};
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MobileSearchBarStyled = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  padding: 15px 10px;
  background: ${colours.disableSelect};
  border-radius: 6px;
  margin-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  &:first-of-type {
    margin-bottom: 10px;
  }

  div.test-element {
    margin: 10px 0;
    max-width: 100%;
  }
  .react-select-container {
    width: 100%;

    .react-select__control {
      justify-content: space-between !important;
      padding: 0 3px 0 10px;
      border-radius: 6px;
      height: 40px;
    }

    .react-select__menu {
      z-index: 9999;
      padding: 16px;
      margin: 0;
      margin-top: 5px;
      max-height: 320px;
      overflow-y: auto;
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        .ant-checkbox-group-item {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        span {
          font-size: 15px;
          text-transform: capitalize;
        }
      }
    }
    &.asset-people-input {
      max-width: 230px;
      margin-right: 0;
    }
    &.asset-subtype-input {
      .react-select__menu {
        z-index: 9999999;
      }
    }
  }
`;
export const StyledMobileSearchFilterButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  width: auto;
  text-align: left;
  max-width: 117px;
  display: block;
  color: #00aeef;
  margin: 0 0 10px 0;
  cursor: pointer;
  img {
    margin-left: 5px;
    max-width: 10px;
    transform: rotate(180deg);
  }
  &.--clear {
    color: rgb(230, 75, 56);
  }
`;

export const StyledMobileSearchFilterDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
