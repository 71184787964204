import DefaultCell from 'components/DataTable/cells/DefaultCell';
import LastSeenCell from 'components/DataTable/cells/LastSeenCell';
import LocationCellAsset from 'components/DataTable/cells/LocationCellAsset';
import { Vertical } from 'gls';
import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { EstimateType } from 'types/Estimate';
import BatterySpan from './content/BatterySpan';

type MobileAssetColumnProps = {
  row: EstimateType;
  onClick: (arg: any) => void;
};
export const MobileAssetColumn = ({ row, onClick }: MobileAssetColumnProps) => {
  return (
    <AssetContainerStyle onClick={() => onClick(row)}>
      <Vertical spacing={10}>
        <span className="asset-name">{row.asset_name}</span>
        {row.asset_type.is_enabled && (
          <span>
            <DefaultCell value={row.asset_type.name} />
          </span>
        )}
        <table>
          <thead>
            <tr>
              <td className="title">LAST SEEN:</td>
              <td>
                {row.last_location_update_timestamp ? (
                  <LastSeenCell timestamp={row.last_location_update_timestamp} />
                ) : (
                  '––'
                )}
              </td>
            </tr>
          </thead>
          <thead>
            <tr>
              <td className="title">LOCATION:</td>
              <td>
                <LocationCellAsset value={row} />
              </td>
            </tr>
          </thead>

          <thead>
            <tr>
              <td className="title">OWNER:</td>
              <td>
                <DefaultCell value={row.owner.name} />
              </td>
            </tr>
          </thead>

          <thead>
            <tr>
              <td className="title">STATUS:</td>
              <td style={{ textTransform: 'capitalize' }}>
                {row.mobility_state === 'unknown' ? '––' : row.mobility_state}{' '}
              </td>
            </tr>
          </thead>

          <thead>
            <tr>
              <td className="title">BATTERY:</td>
              <td>
                <BatterySpan assetDetails={row} />
              </td>
            </tr>
          </thead>
        </table>
      </Vertical>
    </AssetContainerStyle>
  );
};

const AssetContainerStyle = styled.div`
  width: 100%;

  table td {
    vertical-align: top;
    padding-bottom: 4px;
  }
  .title {
    width: 100px;
    color: ${colours.primary};
  }
  .asset-name {
    font-weight: bold;
  }
`;
