import { useContext, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import AssetsApi from 'api/assets/assets.api';
import { AssetsContext } from '../contexts/assets.context';
import {
  getIntersectionFilteredAssets,
  getAssetsWithBuildingAndEstimate,
  getOutdoorEstimates,
} from '../utils/utils';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { VenuesContext } from '../contexts/venues.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import useMapHooksExternalMapRef from './useMapHooksExternalMapRef';
import { UsersContext } from '../contexts/users.context';

export default function useCallSearchAssets() {
  const [noResultsReturned, setNoResultsReturned] = useState(false);

  const { setRawIndoorAssets, setRawOutsideMappedBuildingsAssets, cachedAssetRef } =
    useContext(AssetsContext);
  const authContext = useContext(AuthenticationContext).authState;
  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const { levelsFeatures } = useContext(VenueFilesContext);
  const { usersMarkers } = useContext(UsersContext);
  const {
    setApiCallInProgress,
    setBuildingSelectedID,
    buildingSelectedID,
    buildingIsSelected,
    setClickedMarkerID,
  } = useContext(MapControlContext);
  const { setDoNotCalculateFloor, setInSearchMode, setInFilterMode, setSearchAPIcallInProgress } =
    useContext(SearchFilterContext);
  const {
    fitMapToBoundsOfBuildingLevelsWithAssetsAndOutdoors,
    panMapToProvidedCombinedLevelsAssetUserBounds,
    panMapToCombinedAssetBounds,
  } = useMapHooksExternalMapRef();

  const token = getIdToken(authContext);
  const assetsApi = new AssetsApi(token);

  const callSearchAssets = (term: string) => {
    const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;

    const termTrimmed = term.trim();
    cachedAssetRef.current = null; // reset cached asset.
    setApiCallInProgress(true);
    setSearchAPIcallInProgress(true);
    setDoNotCalculateFloor(false);
    setInSearchMode(false);
    setNoResultsReturned(false);

    return assetsApi
      .getRegisteredBeaconMapAssets(selectedVenueID, termTrimmed)
      .then((res) => {
        const { items } = res.data;
        const estimatesWithBuilding = getAssetsWithBuildingAndEstimate(items);
        const outdoorEstimates = getOutdoorEstimates(items);
        const combinedEstimates = [...estimatesWithBuilding, ...outdoorEstimates];

        setRawIndoorAssets(estimatesWithBuilding);
        setRawOutsideMappedBuildingsAssets(outdoorEstimates);
        setInSearchMode(true);
        setInFilterMode(false);
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        setClickedMarkerID('');

        if (combinedEstimates.length < 1) {
          setNoResultsReturned(true);
          return;
        } else if (combinedEstimates.length === 1) {
          // cache single asset estimate after search in ref
          // so we can compare this in polling.
          cachedAssetRef.current = combinedEstimates[0];
          const estimateHasBuilding =
            !combinedEstimates[0].location.is_outdoors &&
            combinedEstimates[0].location.building_level !== undefined;

          if (!buildingIsSelected && estimateHasBuilding) {
            // if one result is returned, no building is selected & this asset has a building,
            // programatically select the building that this asset belongs to
            // so the user can see it.
            // NOTE that this only sets the associated builiding GeoJSON to display,
            // the actual programatic floor change is handled in useFloorSelectionHook.
            // which uses buildingSelectedID as a dep.

            const singleMatchedAssetBuildingID =
              estimatesWithBuilding[0].location.building_level.possible_buildings[0].id;

            setBuildingSelectedID(singleMatchedAssetBuildingID);
          }
        }
        // execute appropriate panning function, depending if building is selected or not.
        if (buildingSelectedID) {
          fitMapToBoundsOfBuildingLevelsWithAssetsAndOutdoors(
            levelsFeatures,
            combinedEstimates,
            buildingSelectedID,
          );
        } else if (!buildingIsSelected) {
          panMapToCombinedAssetBounds(combinedEstimates);
        }

        return combinedEstimates;
      })
      .catch((err) => {
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        alertErrorMessage(`Search Failed`);
        console.log(err.message);
      });
  };

  const callFilterAssets = (
    inputRef: any,
    assetOwnerQueryArray: string[],
    assetSubtypeQueryArray: string[],
  ) => {
    const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;

    setApiCallInProgress(true);
    setSearchAPIcallInProgress(true);

    return assetsApi
      .getRegisteredBeaconFilteredMapAssets(
        selectedVenueID,
        assetOwnerQueryArray,
        assetSubtypeQueryArray,
      )
      .then((res) => {
        const { items } = res.data;
        const filteredItems = getAssetsWithBuildingAndEstimate(items);
        const outdoorItems = getOutdoorEstimates(items);
        const intersectionalFilteredIndoorAssets = getIntersectionFilteredAssets(
          filteredItems,
          assetOwnerQueryArray,
          assetSubtypeQueryArray,
        );
        const intersectionalFilteredOutdoorAssets = getIntersectionFilteredAssets(
          outdoorItems,
          assetOwnerQueryArray,
          assetSubtypeQueryArray,
        );
        const combinedEstimates = [
          ...intersectionalFilteredIndoorAssets,
          ...intersectionalFilteredOutdoorAssets,
        ];
        setRawIndoorAssets(intersectionalFilteredIndoorAssets);
        setRawOutsideMappedBuildingsAssets(intersectionalFilteredOutdoorAssets);
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        setInFilterMode(true);
        setInSearchMode(false);

        // keep focus on  input after api call, so we can close menu properly.
        if (inputRef && inputRef.current) inputRef.current.focus();

        if (combinedEstimates.length > 0) {
          // only re-center map if assets returned from search.
          panMapToProvidedCombinedLevelsAssetUserBounds(
            combinedEstimates,
            usersMarkers,
            levelsFeatures,
          );
        }
        if (combinedEstimates.length === 1) {
          // cache single asset estimate after filter in ref
          // so we can compare this in polling.
          // note the single estimate behaviour in filters differs from the search in that we do not select the building.

          cachedAssetRef.current = combinedEstimates[0];
        }
        return combinedEstimates;
      })
      .catch((err) => {
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        alertErrorMessage('could not search');
        console.log(err);
      });
  };

  return {
    callSearchAssets,
    noResultsReturned,
    setNoResultsReturned,
    callFilterAssets,
  };
}
