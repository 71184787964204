import { useContext } from 'react';
import { AssetsContext } from '../contexts/assets.context';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { UsersContext } from '../contexts/users.context';
import { getOrdinalFromConditions } from '../utils/getOrdinalFromConditions';
import {
  getAssetEstimatesOfSelectedBuilding,
  getAssociatedLevelIDsArrayFromOrdinal,
  getOrdinalFromLevelID,
  getUserEstimatesOfSelectedBuilding,
} from '../utils/utils';
import useBuildingLevelUtilHooks from './useBuildingLevelUtilHooks';
import useSubscribeToGlobalContext from './useSubscribeToGlobalContext';
import useFilteredAssets from './useFilteredAssets';
import { URLParamContext } from '../contexts/urlParam.context';
import useMapHooks from './useMapHooks';

// a hook to encompass logic needed for floor calculation functions needed in useFloorSelection Effects.
export default function useFloorSelectionLogicHooks() {
  const { usersMarkers } = useContext(UsersContext);
  const { rawIndoorAssets } = useContext(AssetsContext);
  const { urlParams } = useContext(URLParamContext);

  const getVisibleAssets = useFilteredAssets();
  const {
    setSelectedFloorOrdinal,
    selectedFloorOrdinal,
    buildingSelectedID,
    setFloorSelectedIDArray,
  } = useContext(MapControlContext);

  const { inFilterMode, inSearchMode } = useContext(SearchFilterContext);
  const { getGlobalCTXValueOrFallback } = useSubscribeToGlobalContext();
  const { associatedBuildingLevelFeatures } = useBuildingLevelUtilHooks();
  const { fitMapToBoundsOfGeoJSON } = useMapHooks();

  function calculateFloorOrdinalAndIDArrayFromURLParam() {
    const foundAssetOrdinal = getOrdinalFromLevelID(
      associatedBuildingLevelFeatures,
      urlParams.levelID,
    );
    // check the floor can be found first
    if (foundAssetOrdinal !== undefined) {
      const levelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
        foundAssetOrdinal,
        associatedBuildingLevelFeatures,
      );

      setFloorSelectedIDArray(levelIDsArray);
      setSelectedFloorOrdinal(foundAssetOrdinal);
      fitMapToBoundsOfGeoJSON(associatedBuildingLevelFeatures);
    }
  }

  function calculateFloorOrdinalAndIDArrayFromGlobalCTX() {
    const globalFloorOrdinalValue = getGlobalCTXValueOrFallback('GLOBAL_mapFloorOrdinal', null);
    const levelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
      globalFloorOrdinalValue,
      associatedBuildingLevelFeatures,
    );

    setFloorSelectedIDArray(levelIDsArray);
    setSelectedFloorOrdinal(globalFloorOrdinalValue);
  }

  function calculateFloorOrdinalAndIDArray() {
    const assetMarkersSelectedBuilding = getAssetEstimatesOfSelectedBuilding(
      getVisibleAssets(rawIndoorAssets),
      buildingSelectedID,
    );
    const userMarkersSelectedBuilding = getUserEstimatesOfSelectedBuilding(
      usersMarkers,
      buildingSelectedID,
    );
    // derive ordinal from available floors and various conditions to move floor selection to.
    const derivedOrdinalToUse = getOrdinalFromConditions(
      assetMarkersSelectedBuilding,
      userMarkersSelectedBuilding,
      associatedBuildingLevelFeatures,
      selectedFloorOrdinal,
      inFilterMode,
      inSearchMode,
    );
    const levelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
      derivedOrdinalToUse,
      associatedBuildingLevelFeatures,
    );
    // set selected floor to this index, and set the floor ID array. So it can be read in geojson & markers, and floor selection buttons.
    setFloorSelectedIDArray(levelIDsArray);
    setSelectedFloorOrdinal(derivedOrdinalToUse);
  }

  return {
    calculateFloorOrdinalAndIDArrayFromURLParam,
    calculateFloorOrdinalAndIDArray,
    calculateFloorOrdinalAndIDArrayFromGlobalCTX,
  };
}
