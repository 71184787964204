import React, { useContext } from 'react';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import { SearchBarContainerStyled } from 'views/MapPage/Styled';
import { MobileSearchBarStyled, StyledMobileMapFilterPanel } from './mobileStyles';
import { StyledInputContainer } from 'components/AsyncSelect/Styled';
import Button from 'components/Button/Button';
import MobileAsyncSelectSearch from './MobileAsyncSelectSearch';
import NoSearchResultsPanel from '../NoSearchResultsPanel';
import MobileAssetSubTypeDropdownFilterInput from './MobileAssetSubTypeDropdownFilterInput';
import MobileAssetOwnerDropdownFilterInput from './MobileAssetOwnerDropdownFilterInput';
import NoFilterResultsPanel from '../NoFilterResultsPanel';
import useDevice from 'hooks/useDevice';
import MobileAssetsPeopleCheckBoxGroup from './MobileAssetsPeopleCheckBoxGroup';
import useMobileFilterPanel from 'views/MapPage/hooks/useMobileFilterPanel';

export default function MobileMapSearchFilterPanel({
  setMobileMapFilterMenuOpen,
  mobileMapFilterMenuOpen,
}: {
  setMobileMapFilterMenuOpen: (arg: boolean) => void;
  mobileMapFilterMenuOpen: boolean;
}) {
  const { isMobileDevice } = useDevice();
  const { apiCallInProgress } = useContext(MapControlContext);
  const {
    handleClosePanel,
    resetSearchFromCheckbox,
    handleMobileSearchFilters,
    setAssetUserCheckboxArray,
    assetUserCheckboxArray,
    noFilterResultsReturned,
    noSearchResultsReturned,
  } = useMobileFilterPanel({ setMobileMapFilterMenuOpen });

  const { searchTerm } = useContext(SearchFilterContext);

  function handleInputClick(ev: React.SyntheticEvent) {
    const target = ev.target as HTMLTextAreaElement;
    const targetClassName = target.classList[0];
    // prevent anything occuring if input was not clicked.
    if (targetClassName !== 'asyncSearchPrefix__input') return;
  }

  return (
    <StyledMobileMapFilterPanel
      title="Search & Filter"
      from="left"
      isOpen={mobileMapFilterMenuOpen}
      onRequestClose={() => {
        handleClosePanel();
      }}
    >
      <SearchBarContainerStyled>
        <p style={{ marginBottom: '10px' }} className="searchbar-readout">
          Search
        </p>
        <MobileSearchBarStyled>
          <StyledInputContainer
            width={'100%'}
            onClick={(ev: React.SyntheticEvent) => handleInputClick(ev)}
          >
            <MobileAsyncSelectSearch handleMobileSearchFilters={handleMobileSearchFilters} />
            {noSearchResultsReturned && <NoSearchResultsPanel searchTerm={searchTerm} />}
          </StyledInputContainer>
        </MobileSearchBarStyled>

        {!isMobileDevice && (
          <SearchBarContainerStyled>
            <p style={{ marginBottom: '10px' }} className="searchbar-readout">
              Show
            </p>
            <MobileSearchBarStyled>
              <MobileAssetsPeopleCheckBoxGroup
                assetUserCheckboxArray={assetUserCheckboxArray}
                setAssetUserCheckboxArray={setAssetUserCheckboxArray}
              />
            </MobileSearchBarStyled>
          </SearchBarContainerStyled>
        )}

        <p className="searchbar-readout">Filter</p>
        <MobileSearchBarStyled>
          <MobileAssetSubTypeDropdownFilterInput
            resetSearchFromCheckbox={resetSearchFromCheckbox}
          />
          <MobileAssetOwnerDropdownFilterInput resetSearchFromCheckbox={resetSearchFromCheckbox} />
          {noFilterResultsReturned && <NoFilterResultsPanel />}
        </MobileSearchBarStyled>
      </SearchBarContainerStyled>
      <Button isLoading={apiCallInProgress} onClick={handleMobileSearchFilters}>
        Done
      </Button>
    </StyledMobileMapFilterPanel>
  );
}
