import { useContext } from 'react';
import { alertErrorMessage } from 'utils/alerts';
import { AssetsContext } from '../contexts/assets.context';
import { URLParamContext } from '../contexts/urlParam.context';
import {
  checkBuildingUUIDExistsWithinBuildingFeatures,
  checkLevelIDExistsWithinLevelFeatures,
  getAPIMatchedAssetFromBeaconID,
} from '../utils/utils';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import {
  checkFullAssetURLParamsExist,
  checkFullMobileClickThroughParamsExist,
} from '../utils/urlParamUtils';

export default function useURLParamEffects() {
  const { rawIndoorAssets } = useContext(AssetsContext);
  const { buildingFeatures, levelsFeatures } = useContext(VenueFilesContext);
  const { urlParams } = useContext(URLParamContext);

  function checkHandleMatchedAssetExistsFromAPI() {
    // function to handle logic if asset exists from assets API call or not after loading selected building
    const matchedAsset = getAPIMatchedAssetFromBeaconID(rawIndoorAssets, urlParams.beaconID);

    if (!matchedAsset && checkFullAssetURLParamsExist()) {
      alertErrorMessage('Could not locate Asset from Asset List');
    }
  }

  function checkBuildingAndLevelAreWithinVenueData(): boolean {
    // check the level id param exists within the venues available level features
    // and that the building id param has a match from venues avaialable buildings
    return (
      checkLevelIDExistsWithinLevelFeatures(levelsFeatures, urlParams.levelID) &&
      checkBuildingUUIDExistsWithinBuildingFeatures(buildingFeatures, urlParams.buildingID)
    );
  }

  function checkHandleMatchedVenueFromMobileParams() {
    // function to check if user has valid URL params from click through from mobile app

    if (checkFullMobileClickThroughParamsExist() && !checkBuildingAndLevelAreWithinVenueData()) {
      alertErrorMessage('Parameters not supported for this venue');
    }
  }
  return {
    checkHandleMatchedAssetExistsFromAPI,
    checkHandleMatchedVenueFromMobileParams,
    checkBuildingAndLevelAreWithinVenueData,
  };
}
