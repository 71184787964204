import React from 'react';
import { AllowedDisabledFeatureFlagsType } from 'types/FeatureFlags';

export default function FeatureFlagComponentWrapper({
  disabledFlagString,
  children,
  fallbackComponent,
}: {
  disabledFlagString: AllowedDisabledFeatureFlagsType;
  children: React.ReactElement;
  fallbackComponent?: React.ReactElement;
}): React.ReactElement | null {
  // a simple wrapper that wraps around a component, and checks if the feature flag from deployment .env is true. If it is, the wrapper returns null.
  const flagExists = process.env[`REACT_APP_${disabledFlagString}`];

  if (flagExists) {
    return fallbackComponent ? fallbackComponent : null;
  } else return children;
}
