import React from 'react';
// pages
import BeaconManagementPage from 'views/BeaconManagementPage/BeaconManagementPage';
import NotFoundPage from 'views/NotFoundPage/NotFoundPage';
import UnauthenticatedPage from 'views/UnauthenticatedPage/UnauthenticatedPage';
import SupportPage from 'views/SupportPage/SupportPage';
import AssetListPage from 'views/AssetListPage/AssetListPage';
import NoPermissionsPage from 'views/NoPermissionsPage/NoPermissionsPage';
import MapPage from 'views/MapPage/MapPage';
import ErrorPage from 'views/ErrorPage/ErrorPage';
import AuthenticationCallbackPage from 'views/AuthenticationCallbackPage/AuthenticationCallbackPage';
import RecentUsersPage from 'views/RecentUsersPage/RecentUsersPage';
import AssetManagementPage from 'views/AssetManagementPage/AssetManagementPage';
import SignoutPage from 'views/SignoutPage/SignoutPage';
import EditAssetPage from 'views/EditAssetPage/EditAssetPage';
import { PERMISSIONS } from 'config/permissions';
import { featurePermissionType, userPermissionType } from 'types/permissions';
import NoFeaturePermissionsPage from 'views/NoFeaturePermissionsPage/NoFeaturePermissionsPage';
import { routePath } from './routePaths';
import UserLogsPage from 'views/UserLogsPage/UserLogsPage';
import { localLoginRoutes } from './_localroutes';
import MobileSignoutPage from 'views/MobileSignoutPage/MobileSignoutPage';

export type routesType = {
  url: string;
  title?: string;
  isPrivate: boolean;
  navigation: boolean;
  redirectTo?: string;
  component: React.FC;
  navCollapsedDefault: boolean;
  routeFeaturePermissions?: featurePermissionType[];
  routeUserPermissions?: userPermissionType[];
};

const routes: routesType[] = [
  {
    url: '/',
    isPrivate: true,
    navigation: true,
    redirectTo: '/map',
    component: MapPage,
    navCollapsedDefault: true,
    routeFeaturePermissions: [
      PERMISSIONS.FEATURE.ASSET_TRACKING,
      PERMISSIONS.FEATURE.USER_TRACKING,
    ],
  },
  {
    url: '/map',
    title: 'Map View',
    isPrivate: true,
    navigation: true,
    component: MapPage,
    navCollapsedDefault: true,
    routeFeaturePermissions: [
      PERMISSIONS.FEATURE.ASSET_TRACKING,
      PERMISSIONS.FEATURE.USER_TRACKING,
    ],
    routeUserPermissions: [PERMISSIONS.USER.READ],
  },
  {
    url: 'assets',
    title: 'Asset List',
    isPrivate: true,
    navigation: true,
    component: AssetListPage,
    navCollapsedDefault: false,
    routeUserPermissions: [PERMISSIONS.USER.READ],
    routeFeaturePermissions: [PERMISSIONS.FEATURE.ASSET_TRACKING],
  },
  {
    url: routePath.assetManagement,
    title: 'Asset Management',
    isPrivate: true,
    navigation: true,
    component: AssetManagementPage,
    navCollapsedDefault: false,
    routeFeaturePermissions: [PERMISSIONS.FEATURE.ASSET_TRACKING],
    routeUserPermissions: [PERMISSIONS.USER.WRITE],
  },
  {
    url: routePath.beaconManagement,
    title: 'Beacon Management',
    isPrivate: true,
    navigation: true,
    component: BeaconManagementPage,
    navCollapsedDefault: false,
    routeFeaturePermissions: [PERMISSIONS.FEATURE.ASSET_TRACKING],
    routeUserPermissions: [PERMISSIONS.USER.WRITE],
  },
  {
    url: 'edit-asset',
    title: 'Edit Asset',
    isPrivate: true,
    navigation: true,
    component: EditAssetPage,
    navCollapsedDefault: false,
    routeUserPermissions: [PERMISSIONS.USER.WRITE],
    routeFeaturePermissions: [PERMISSIONS.FEATURE.ASSET_TRACKING],
  },
  {
    url: routePath.recentUsers,
    title: 'Users: Current Locations',
    isPrivate: true,
    navigation: true,
    component: RecentUsersPage,
    navCollapsedDefault: false,
    routeUserPermissions: [PERMISSIONS.USER.READ],
    routeFeaturePermissions: [PERMISSIONS.FEATURE.USER_TRACKING],
  },
  {
    url: routePath.userLogs,
    title: 'Users: Logs',
    isPrivate: true,
    navigation: true,
    component: UserLogsPage,
    navCollapsedDefault: false,
    routeUserPermissions: [PERMISSIONS.USER.READ],
    routeFeaturePermissions: [PERMISSIONS.FEATURE.USER_TRACKING],
  },
  {
    url: 'unauthenticated',
    title: 'Not Authenticated',
    isPrivate: false,
    navigation: false,
    component: UnauthenticatedPage,
    navCollapsedDefault: false,
  },
  {
    url: 'no-permission',
    title: 'You do not have Permission',
    isPrivate: false,
    navigation: false,
    component: NoPermissionsPage,
    navCollapsedDefault: false,
  },
  {
    url: 'no-feature-permission',
    isPrivate: true,
    navigation: true,
    component: NoFeaturePermissionsPage,
    navCollapsedDefault: false,
  },

  {
    url: 'signout',
    title: 'Sign Out',
    isPrivate: false,
    navigation: false,
    component: SignoutPage,
    navCollapsedDefault: false,
  },
  {
    url: 'mobile-signout',
    title: 'Sign Out',
    isPrivate: false,
    navigation: false,
    component: MobileSignoutPage,
    navCollapsedDefault: false,
  },
  {
    url: 'support',
    title: 'Support',
    isPrivate: true,
    navigation: true,
    component: SupportPage,
    navCollapsedDefault: false,
    routeUserPermissions: [PERMISSIONS.USER.READ],
    routeFeaturePermissions: [PERMISSIONS.FEATURE.ASSET_TRACKING],
  },
  {
    url: 'error',
    title: 'Error',
    isPrivate: false,
    navigation: false,
    component: ErrorPage,
    navCollapsedDefault: false,
  },
  {
    url: 'authentication/callback',
    title: 'Authentication Successful',
    isPrivate: true,
    navigation: false,
    component: AuthenticationCallbackPage,
    navCollapsedDefault: false,
    routeUserPermissions: [PERMISSIONS.USER.READ],
    routeFeaturePermissions: [
      PERMISSIONS.FEATURE.ASSET_TRACKING,
      PERMISSIONS.FEATURE.USER_TRACKING,
    ],
  },
  ...localLoginRoutes,
  {
    url: '*',
    title: 'Not Found',
    isPrivate: false,
    navigation: false,
    component: NotFoundPage,
    navCollapsedDefault: false,
  },
];

export default routes;
