import React from 'react';
import Select, { Props } from 'react-select';
import styled from 'styled-components';
import { colours, coloursNames } from 'styling/colours';

const StyledSelectInput = styled(Select)`
  .react-select__control {
    border: 1px solid ${colours.tertiaryText};
    border-radius: 3px;
    color: ${colours.primaryText};
    font-size: 15px;
    letter-spacing: 0px;
    height: 100%;
    box-shadow: none;
    padding: 7px;
    &.react-select__control--menu-is-open {
      border: 1px solid ${colours.secondary};
    }
    &:hover {
      border-color: ${colours.secondary};
    }
  }
  & .react-select__menu {
    color: ${colours.primaryText};
    font-size: 15px;
    letter-spacing: 0px;
    padding: 7px;
  }
  & .react-select__option--is-selected {
    background-color: ${colours.white};
    color: ${colours.primaryText};
  }
  & .react-select__option--is-focused {
    background-color: ${coloursNames.lightGrey15};
    color: ${colours.secondary};
  }

  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__dropdown-indicator {
    color: ${colours.primaryText};
  }
  & .react-select__option--is-selected {
    color: ${colours.assetDetailsFlyoutHeader};
  }
`;

type SelectInputProps = Props & {
  options: any;
  onChange?: any;
  id?: string;
  defaultValue?: any;
  prePlaceholder?: string;
  ref?: any;
};

// eslint-disable-next-line react/display-name
const SelectInput: React.FunctionComponent<SelectInputProps> = React.forwardRef(
  (props: SelectInputProps, ref) => {
    return (
      <StyledSelectInput
        ref={ref}
        className="react-select-container"
        classNamePrefix="react-select"
        {...props}
      />
    );
  },
);
export default SelectInput;
