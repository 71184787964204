import { useContext } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import {
  getLevelFeatureDataFromBuildingID,
  getLevelFeaturesWithUniqueOrdinals,
  getLevelFeaturesSortedByOrdinal,
} from '../utils/utils';

// a hook to return level features and ordinals to be used by floor control components.
export default function useBuildingLevelUtilHooks() {
  const { levelsFeatures } = useContext(VenueFilesContext);
  const { buildingSelectedID } = useContext(MapControlContext);

  const associatedBuildingLevelFeatures = getLevelFeatureDataFromBuildingID(
    buildingSelectedID,
    levelsFeatures,
  );

  const levelsWithUniqueOrdinals = getLevelFeaturesWithUniqueOrdinals(
    associatedBuildingLevelFeatures,
  );
  const sortedLevelsWithUniqueOrdinals = getLevelFeaturesSortedByOrdinal(levelsWithUniqueOrdinals);

  return {
    associatedBuildingLevelFeatures,
    sortedLevelsWithUniqueOrdinals,
  };
}
