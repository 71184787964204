import { AssetConfigContext } from 'contexts/assetConfig.context';
import React, { useContext } from 'react';

export default function DropdownControlReadout({
  checkedList,
  checkAll,
  type,
}: {
  checkedList: string[];
  checkAll: boolean;
  type: string;
}) {
  const { assetOwners, assetSubtype } = useContext(AssetConfigContext);

  let readoutString = '';
  const singleSelected = checkedList.length === 1;

  function getReadoutString(type: string, value: any): string {
    const derivedOption = type === 'owner' ? assetOwners : assetSubtype;

    return derivedOption.filter((val: any) => val.value === value)[0].label;
  }

  if (checkAll) readoutString = 'All';
  else if (checkedList[0] === 'nop') {
    readoutString = ` (0)`;
  } else {
    readoutString = singleSelected ? getReadoutString(type, checkedList[0]) : '';
  }

  return (
    <div>
      {readoutString}
      {!singleSelected && ` (${checkedList.length})`}
    </div>
  );
}
