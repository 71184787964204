import LoadingBars from 'components/Loading/LoadingBars';
import React from 'react';
import { StyledLoadingOverlayDiv } from '../Styled';

export default function LoadingOverlay(): any {
  return (
    <StyledLoadingOverlayDiv>
      <div className="content-wrapper">
        <LoadingBars barHeight={10} barWidth={3} />
        <span>Loading...</span>
      </div>
    </StyledLoadingOverlayDiv>
  );
}
