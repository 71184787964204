import { useContext } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { FullConfigContext } from 'contexts/fullConfig.context';
import useDevice from 'hooks/useDevice';

export const menuOptionsFull = [
  { value: 'assets', label: 'Asset Name' },
  { value: 'users', label: 'People' },
];
export const menuOptionsPeople = [{ value: 'users', label: 'People' }];
export const menuOptionsAssets = [{ value: 'assets', label: 'Asset Name' }];

export default function useAssetUserSearchMenuOptions() {
  const { showAssetsChecked, showUsersChecked } = useContext(MapControlContext);
  const { assetTrackingPermissionEnabled, userTrackingPermissionEnabled } =
    useContext(FullConfigContext);
  const userHasFullPermissions = assetTrackingPermissionEnabled && userTrackingPermissionEnabled;
  const { isMobileDevice } = useDevice();

  function getMenuOptions() {
    // return menu options relevant for search depending on checkboxes and also user permissions.
    if (isMobileDevice) return menuOptionsAssets; // do not sure user search for mobile.
    if (userHasFullPermissions) {
      if (showAssetsChecked && showUsersChecked) return menuOptionsFull;
      else if (showAssetsChecked && !showUsersChecked) return menuOptionsAssets;
      else if (!showAssetsChecked && showUsersChecked) return menuOptionsPeople;
      else return [];
    } else if (assetTrackingPermissionEnabled) return menuOptionsAssets;
    else if (userTrackingPermissionEnabled) return menuOptionsPeople;
    else return [];
  }

  return { getMenuOptions };
}
