import React, { useEffect, useContext, useRef, useState } from 'react';
import SelectInput from 'components/SelectInput/SelectInput';
import { components } from 'react-select';
import { StyledMapDropdownControlWrapper } from '../../Styled';
import { Checkbox } from 'antd';
import DropdownControlReadout from '../SearchFilterBar/DropdownControlReadout';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import { AssetConfigContext } from 'contexts/assetConfig.context';
import { getSortedArrayByValue } from 'utils/utils';

type AssetOwnerDropdownFilterInputType = {
  resetSearchFromCheckbox: () => void;
};
export default function MobileAssetOwnerDropdownFilterInput({
  resetSearchFromCheckbox,
}: AssetOwnerDropdownFilterInputType) {
  const [ownerDropdownOpen, setOwnerDropdownOpen] = useState(false);

  const componentKeyRef = useRef(Math.random() * 100); // create random number ref.

  const { apiCallInProgress } = useContext(MapControlContext);
  const {
    inSearchMode,
    assetOwnerCheckedListArray,
    setAssetOwnerCheckedListArray,
    assetOwnerCheckAll,
    setAssetOwnerCheckAll,
    inFilterMode,
    setInFilterMode,
    setAssetOwnerFilterModified,
    defaultAssetOwnerCheckedListArray,
  } = useContext(SearchFilterContext);
  const { assetOwners } = useContext(AssetConfigContext);

  const alphaSortedOptions = getSortedArrayByValue(assetOwners, 'value');

  const handleCheckAllChange = (e: any) => {
    setAssetOwnerCheckedListArray(e.target.checked ? defaultAssetOwnerCheckedListArray : ['nop']);
    setAssetOwnerCheckAll(e.target.checked);
    resetSearchFromCheckbox();
    setAssetOwnerFilterModified(!e.target.checked);
  };

  const handleCheckboxClick = (list: any) => {
    setAssetOwnerCheckedListArray(list.length === 0 ? ['nop'] : list);
    setAssetOwnerCheckAll(list.length === defaultAssetOwnerCheckedListArray.length);
    resetSearchFromCheckbox();
    setAssetOwnerFilterModified(list.length !== defaultAssetOwnerCheckedListArray.length);
  };

  useEffect(() => {
    //reset checked to all, after performing a search
    if (inSearchMode) {
      setAssetOwnerCheckedListArray(defaultAssetOwnerCheckedListArray);
      setAssetOwnerFilterModified(false);
      setAssetOwnerCheckAll(true);
      setOwnerDropdownOpen(false);
      setInFilterMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inSearchMode]);

  useEffect(() => {
    // set checked list to ALL, after load and resetting filters
    if (!inFilterMode) {
      setAssetOwnerCheckedListArray(defaultAssetOwnerCheckedListArray);
      setAssetOwnerFilterModified(false);
      setAssetOwnerCheckAll(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // create new ref from random number after search occurs, and if checklist changes, Forcing input to re-render
    if (apiCallInProgress) {
      componentKeyRef.current = Math.random() * 100;
    }
  }, [apiCallInProgress, assetOwnerCheckedListArray]);

  return (
    <SelectInput
      key={componentKeyRef.current}
      options={alphaSortedOptions}
      placeholder={false}
      openMenuOnClick
      isSearchable={false}
      blurInputOnSelect
      onKeyDown={(ev) => {
        // prevent user inputting keys, as we are just using dropdown.
        ev.preventDefault();
      }}
      components={{
        Control: ({ children, ...rest }) => {
          setOwnerDropdownOpen(rest.menuIsOpen);

          return (
            <StyledMapDropdownControlWrapper style={{ marginTop: '10px' }}>
              <components.Control {...rest}>
                <div style={{ display: 'flex' }}>
                  <span className="react-control__preplaceholder">Owner: </span>
                  <DropdownControlReadout
                    type="owner"
                    checkAll={assetOwnerCheckAll}
                    checkedList={assetOwnerCheckedListArray}
                  />
                </div>

                {children}
              </components.Control>
            </StyledMapDropdownControlWrapper>
          );
        },
        DropdownIndicator: ({ ...rest }) => (
          <button style={{ transform: `rotate(${ownerDropdownOpen ? '180deg' : '0deg'}` }}>
            <components.DropdownIndicator {...rest} />
          </button>
        ),
        Menu: ({ ...rest }) => {
          return (
            <components.Menu {...rest}>
              <Checkbox
                disabled={apiCallInProgress}
                style={{ marginBottom: '20px', width: '100%' }}
                checked={assetOwnerCheckAll}
                onChange={handleCheckAllChange}
              >
                All
              </Checkbox>

              <Checkbox.Group
                disabled={apiCallInProgress}
                value={assetOwnerCheckedListArray}
                options={alphaSortedOptions}
                defaultValue={defaultAssetOwnerCheckedListArray}
                onChange={handleCheckboxClick}
              />
            </components.Menu>
          );
        },
      }}
    />
  );
}
