import SelectInput from 'components/SelectInput/SelectInput';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { StyledMapDropdownControlWrapper } from 'views/MapPage/Styled';
import { components } from 'react-select';
import { Checkbox } from 'antd';
import { getArrayOfValuesFromOptions } from 'views/MapPage/utils/utils';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

type optionsType = {
  value: string;
  label: string;
};

const options: optionsType[] = [
  {
    value: 'assets',
    label: 'Assets',
  },
  {
    value: 'people',
    label: 'People',
  },
];

function getTextToDisplay(selectedCheckboxArray: string[]) {
  if (selectedCheckboxArray.length === 2) return 'Assets & People';
  else return selectedCheckboxArray;
}

const CustomControlComponent = ({
  selectedCheckboxArray,
  children,
  props,
}: {
  selectedCheckboxArray: string[];
  children: ReactNode;
  props: any;
}) => {
  return (
    <StyledMapDropdownControlWrapper>
      <components.Control {...props}>
        <div style={{ display: 'flex' }}>
          <span className="react-control__preplaceholder">Show: </span>
          <span style={{ textTransform: 'capitalize' }}>
            {getTextToDisplay(selectedCheckboxArray)}
          </span>
        </div>
        {children}
      </components.Control>
    </StyledMapDropdownControlWrapper>
  );
};

const CustomMenuComponent = ({
  props,
  selectedCheckboxArray,
  defaultOptions,
  handleCheckboxClick,
}: {
  props: any;
  selectedCheckboxArray: string[];
  defaultOptions: any;
  handleCheckboxClick: (param: any) => void;
}) => {
  return (
    <components.Menu {...props}>
      <Checkbox.Group
        value={selectedCheckboxArray}
        options={options}
        defaultValue={defaultOptions}
        onChange={handleCheckboxClick}
      />
    </components.Menu>
  );
};

export default function ShowAssetPeopleDropdown() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const defaultOptions = getArrayOfValuesFromOptions(options);
  const [selectedCheckboxArray, setSelectedCheckboxArray] = useState<any>(defaultOptions);
  const { setShowAssetsChecked, setShowUsersChecked } = useContext(MapControlContext);

  const handleCheckboxClick = (e: CheckboxValueType[]) => {
    if (e.length >= 1) {
      setSelectedCheckboxArray(e);
    } else {
      const nextSelected = getDeterminedSelection();

      setSelectedCheckboxArray([nextSelected]);
    }
  };

  function getDeterminedSelection() {
    // ensure always one option is selected. If user unselects single checkbox, we select the opposite one.
    const previousSelected = selectedCheckboxArray[0];
    const oppositeOfSelected = defaultOptions.find(
      (option: string[]) => option !== previousSelected,
    );

    return oppositeOfSelected;
  }

  useEffect(() => {
    // keep track of checkboxes in dropdown, and update states accordingly.
    const assetsChecked = selectedCheckboxArray.includes('assets');
    const usersChecked = selectedCheckboxArray.includes('people');

    setShowAssetsChecked(assetsChecked);
    setShowUsersChecked(usersChecked);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckboxArray]);

  return (
    <SelectInput
      menuIsOpen={menuIsOpen}
      className="react-select-container asset-people-input"
      options={options}
      placeholder={false}
      openMenuOnClick
      onMenuClose={() => {
        setMenuIsOpen(false);
      }}
      onMenuOpen={() => {
        setMenuIsOpen(true);
      }}
      onKeyDown={(ev) => {
        // prevent user inputting keys, as we are just using dropdown.
        ev.preventDefault();
      }}
      components={{
        Control: ({ children, ...props }) => (
          <CustomControlComponent props={props} selectedCheckboxArray={selectedCheckboxArray}>
            {children}
          </CustomControlComponent>
        ),
        DropdownIndicator: ({ ...rest }) => (
          <button>
            <components.DropdownIndicator {...rest} />
          </button>
        ),
        Menu: ({ ...props }) => (
          <CustomMenuComponent
            props={props}
            selectedCheckboxArray={selectedCheckboxArray}
            defaultOptions={defaultOptions}
            handleCheckboxClick={(e: any) => handleCheckboxClick(e)}
          />
        ),
      }}
    />
  );
}
