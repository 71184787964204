import React, { useEffect, createContext, ReactNode, useState, useContext } from 'react';
import { callGetUserInfo } from 'api/Oauth2/user';
import { getAccessToken } from 'utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import { AuthenticationContext } from './authentication.context';
import LoadingPlaceholderSkeletonPage from 'components/Loading/LoadingPlaceholderPage/LoadingPlaceholderSkeletonPage';
import { initialPendo } from 'pendo';
import { FullConfigContext } from './fullConfig.context';

interface userContextI {
  sub: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  email: string;
  username: string;
}
const initialUserState = {
  sub: '',
  email_verified: false,
  name: '',
  nickname: '',
  email: '',
  username: '',
};

export const UserContext = createContext({} as userContextI);

type UserProviderProps = { children: ReactNode };

const UserProvider = ({ children }: UserProviderProps) => {
  const { authState } = useContext(AuthenticationContext);
  const [userState, setUserState] = useState(initialUserState);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { runPendo } = useContext(FullConfigContext);

  useEffect(() => {
    // once user is authenticated and we have signin session, we can retrieve user info from API using access token.

    if (authState.isAuthenticated && authState.signInUserSession) {
      // obtain access token from amplify auth obj and use it as token for Oath2 api
      const accessToken = getAccessToken(authState);

      callGetUserInfo(accessToken)
        .then(function (response) {
          // handle success
          setUserState(response.data);
          setHasLoaded(true);
          const userInfo: userContextI = response.data;
          if (runPendo) {
            initialPendo(userInfo.username, userInfo.email, userInfo.name);
            console.log('Pendo is running.');
          }
        })
        .catch(function (error) {
          if (error.response) {
            const errorString = `${error.response.data.error}`;
            alertErrorMessage(errorString);
          }
          setHasLoaded(true);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isAuthenticated]);
  // bypass this during tests
  return hasLoaded || authState.isMock ? (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  ) : (
    <LoadingPlaceholderSkeletonPage />
  );
};

export default UserProvider;
