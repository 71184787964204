import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';

const StyledHeader = styled.header`
  margin-bottom: 10px;
  h2 {
    font-size: 18px;
    color: ${colours.secondaryText};
  }
`;

export default function FormHeader({ text }: { text: string }) {
  return (
    <StyledHeader>
      <h2>{text}</h2>
    </StyledHeader>
  );
}
