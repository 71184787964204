import { MOBILEDEVICEWIDTH } from 'config/constants';
import { useEffect, useState } from 'react';

const breakWidth = MOBILEDEVICEWIDTH;
const minimumWidth = 375; // pixel 3a width
const tabletWidth = 1280;
const shortViewportHeight = 899;

function isMobileWidth() {
  return window.innerWidth <= breakWidth;
}
function isMinimumSupportedWidth() {
  return window.innerWidth >= minimumWidth;
}
function isTabletWidth() {
  return window.innerWidth <= tabletWidth;
}

function isShortViewportHeight() {
  return window.innerHeight <= shortViewportHeight;
}

const useDevice = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(isMobileWidth());
  const [isTabletDevice, setIsTabletDevice] = useState(isTabletWidth());
  const [isShortViewportDevice, setIsShortViewportDevice] = useState(isShortViewportHeight());
  const [screenIsMinimumSupportedWidth, setScreenIsMinimumSupportedWidth] = useState(
    isMinimumSupportedWidth(),
  );
  function handleResize() {
    setIsMobileDevice(isMobileWidth());
    setIsTabletDevice(isTabletWidth());
    setIsShortViewportDevice(isShortViewportHeight());
    setScreenIsMinimumSupportedWidth(isMinimumSupportedWidth());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isMobileDevice, isTabletDevice, screenIsMinimumSupportedWidth, isShortViewportDevice };
};

export default useDevice;
